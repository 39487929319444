import { FormattedMessage } from 'react-intl';

import { TwoLinesSeparator } from '../../../components/layout/layoutElements';
import SectionHeader from './SectionHeader';

export default function ContactInformation({ contact }) {
  if (!contact) return null;

  return (
    <>
      <SectionHeader title="admin.userManagement.forms.contactInformation" />
      <FormattedMessage id={contact.name} />
      <TwoLinesSeparator />
      <FormattedMessage id={contact.phone} />
      <TwoLinesSeparator />
      <FormattedMessage id={contact.email} />
    </>
  );
}
