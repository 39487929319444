import { Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

export default function SectionHeader({ title }) {
  return (
    <Row className="QuoteHeader" align="middle" justify="space-between">
      <Typography.Title level={4}>
        <FormattedMessage id={title} />
      </Typography.Title>
    </Row>
  );
}
