import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import { ShipmentStage } from '../../app/enums/ShipmentStage';
import { DELETE_SHIPMENT_MUTATION } from '../../app/graphql/jobFormQueries';
import { JOBS_QUERY } from '../../app/graphql/jobQueries';
import IconButton from '../../components/buttons/IconButton';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';
import { SimpleConfirmDialogButton } from '../../components/dialogs/SimpleConfirmDialog';
import { MonitorContext } from './monitorContexts';
import {
  DELETE_QUOTE_MUTATION,
  QUOTES_QUERY,
} from '../../app/graphql/quoteQueries';
import { QuoteStage } from '../../app/enums/QuoteStage';

function useDeleteShipment({ jobNumber }) {
  const { variables } = useContext(MonitorContext);
  const [deleteJob] = useMutation(DELETE_SHIPMENT_MUTATION, {
    update: cache => {
      const res = cache.readQuery({
        query: JOBS_QUERY,
        variables: {
          ...variables,
          filter: { ...variables.filter, stage: ShipmentStage.SAVED },
        },
      });
      if (res) {
        const {
          customerShipments: { data, total },
        } = res;
        const filteredData = data.filter(row => row.jobNumber !== jobNumber);
        const expectedNewTotal = total - data.length + filteredData.length;

        cache.writeQuery({
          query: JOBS_QUERY,
          variables: {
            ...variables,
            filter: { ...variables.filter, stage: ShipmentStage.SAVED },
          },
          data: {
            customerShipments: {
              data: filteredData,
              total: expectedNewTotal,
              pageIndex: 1,
              pageSize: filteredData.length + 1, // To trigger full rewrite instead of page splice
            },
          },
        });
      }
    },
    variables: { jobNumber },
  });

  return deleteJob;
}

function useDeleteQuote({ referenceNo }) {
  const { variables } = useContext(MonitorContext);
  const [deleteQuote] = useMutation(DELETE_QUOTE_MUTATION, {
    update: cache => {
      const res = cache.readQuery({
        query: QUOTES_QUERY,
        variables: {
          ...variables,
          filter: { ...variables.filter, stage: QuoteStage.ACTIVE },
        },
      });
      if (res) {
        const {
          customerQuotes: { data, total },
        } = res;
        const filteredData = data.filter(
          row => row.referenceNo !== referenceNo
        );
        const expectedNewTotal = total - data.length + filteredData.length;

        cache.writeQuery({
          query: QUOTES_QUERY,
          variables: {
            ...variables,
            filter: { ...variables.filter, stage: QuoteStage.ACTIVE },
          },
          data: {
            customerQuotes: {
              data: filteredData,
              total: expectedNewTotal,
              pageIndex: 1,
              pageSize: filteredData.length + 1, // To trigger full rewrite instead of page splice
            },
          },
        });
      }
    },
    variables: { referenceNo },
  });

  return deleteQuote;
}

export function DeleteShipmentButton({ jobNumber }) {
  const { errorMessage, successMessage } = useFlashMessageContext();
  const deleteJob = useDeleteShipment({ jobNumber });

  return (
    <SimpleConfirmDialogButton
      cancelTextId="buttons.cancel"
      okTextId="buttons.delete"
      onOk={async () => {
        try {
          await deleteJob();
          successMessage({
            contentId: 'job.delete.success',
          });
        } catch (e) {
          errorMessage(e);
        }
      }}
      textId="job.delete.text"
      className="SimpleDialog--TransformCenter"
      transitionName="scale-up"
    >
      {({ open }) => (
        <IconButton
          icon="times-circle"
          className="MonitorJobList__DeleteButton icon-18"
          onClick={e => {
            open();
            e.stopPropagation();
          }}
        />
      )}
    </SimpleConfirmDialogButton>
  );
}

export function DeleteQuoteButton({ referenceNo }) {
  const { errorMessage, successMessage } = useFlashMessageContext();
  const deleteQuote = useDeleteQuote({ referenceNo });

  return (
    <SimpleConfirmDialogButton
      cancelTextId="buttons.cancel"
      okTextId="buttons.delete"
      onOk={async () => {
        try {
          await deleteQuote();
          successMessage({
            contentId: 'quote.delete.success',
          });
        } catch (e) {
          errorMessage(e);
        }
      }}
      textId="quote.delete.text"
      className="SimpleDialog--TransformCenter"
      transitionName="scale-up"
    >
      {({ open }) => (
        <IconButton
          icon="times-circle"
          className="MonitorJobList__DeleteButton icon-18"
          onClick={e => {
            open();
            e.stopPropagation();
          }}
        />
      )}
    </SimpleConfirmDialogButton>
  );
}
