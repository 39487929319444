import { Col, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { TwoLinesSeparator } from '../../../components/layout/layoutElements';
import DateTimeDisplay from './DateTimeDisplay';
import SectionHeader from './SectionHeader';
import ContactInformation from './ContactInformation';
import {
  DateFormatMedium,
  DateFormatNumeric,
  DateTimeFormatShort,
} from '../../../utils/dateFormatting';
import { cssVariables } from '../../../styles/cssVariables';
import Badge from './components/Badge';
import FlightInformationSection from './components/FlightInformationSection';

export default function ShipmentDetails({
  title,
  sectionTitle,
  company,
  address,
  dateTime,
  flight,
  contact,
}) {
  return (
    <Col span={6}>
      <div className="ShipmentDetails">
        <TwoLinesSeparator
          style={{
            marginTop: cssVariables.spaceLg,
            marginBottom: cssVariables.spaceSm,
          }}
        />
        <Typography.Title level={3} className="text-uppercase shipment-title">
          <FormattedMessage id={title} />
        </Typography.Title>
        <TwoLinesSeparator />
        <Typography.Title level={3} className="text-uppercase shipment-status">
          <FormattedMessage id="book.quote.flightInformation.ready" />
          <DateTimeDisplay
            dateTime={dateTime}
            formatComponent={DateFormatMedium}
          />
        </Typography.Title>

        {/* From/To Section */}
        <FlightInformationSection
          sectionTitle={sectionTitle}
          dateTime={dateTime}
          highlightedText={company}
          address={address}
          badgeLabel="RDY"
          distance={4}
        />
        {/* Flight Information */}
        <FlightInformationSection
          sectionTitle="book.newQuote.toDeliver"
          dateTime={dateTime}
          highlightedText="Flight: DL 979"
          flight={flight}
          badgeLabel="L/O"
        />
        {/* Contact Information */}
        <ContactInformation contact={contact} />
      </div>
    </Col>
  );
}
