import { FormattedMessage } from 'react-intl';

import { TwoLinesSeparator } from '../../../../components/layout/layoutElements';

export default function AddressDisplay({ address }) {
  const { addressLine1, addressLine2, city, stateProvince, postalCode } =
    address || {};
  return (
    <>
      {addressLine1 && (
        <>
          <FormattedMessage id={addressLine1} />
          <TwoLinesSeparator />
        </>
      )}
      {addressLine2 && (
        <>
          <FormattedMessage id={addressLine2} />
          <TwoLinesSeparator />
        </>
      )}
      {city.text && (
        <>
          <FormattedMessage id={city.text} />,{' '}
        </>
      )}
      {stateProvince.code && (
        <>
          <FormattedMessage id={stateProvince.code} />{' '}
        </>
      )}
      {postalCode && <FormattedMessage id={postalCode} />}
    </>
  );
}
