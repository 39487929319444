export function convertUnit(value, sourceUnit, targetUnit) {
  return (value * sourceUnit.relativeValue) / targetUnit.relativeValue;
}

export const WeightUnit = {
  KILOGRAM: {
    name: 'kilogram',
    short: 'kg',
    suffix: ' Kgs',
    relativeValue: 1,
  },
  POUND: {
    name: 'pound',
    short: 'lb',
    suffix: ' lbs',
    relativeValue: 0.453592,
  },
};

export const LengthUnit = {
  METER: {
    name: 'meter',
    short: 'm',
    suffix: ' m',
    relativeValue: 1,
  },
  CENTIMETER: {
    name: 'centimeter',
    short: 'cm',
    suffix: ' cm',
    relativeValue: 0.01,
  },
  MILIMETER: {
    name: 'milimeter',
    short: 'mm',
    suffix: ' mm',
    relativeValue: 0.001,
  },
  INCH: {
    name: 'inch',
    short: 'in',
    suffix: ' in',
    relativeValue: 0.0254,
  },
};

export const TimeUnit = {
  HOUR: {
    name: 'hour',
    short: 'h',
    suffix: ' hrs',
    relativeValue: 3600,
  },
};

export const DistanceUnit = {
  KILOMETERS: {
    name: 'kilometers',
    short: 'km',
    suffix: ' km',
    relativeValue: 1,
  },
  MILES: {
    name: 'miles',
    short: 'mi',
    suffix: ' mi',
    relativeValue: 0.621371192,
  },
};

export const UnitSystem = {
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL',
};

export const UnitSystemValues = [UnitSystem.IMPERIAL, UnitSystem.METRIC];

export const UnitSystems = {
  [UnitSystem.METRIC]: {
    weight: WeightUnit.KILOGRAM,
    length: LengthUnit.CENTIMETER,
    time: TimeUnit.HOUR,
    distance: DistanceUnit.KILOMETERS,
  },
  [UnitSystem.IMPERIAL]: {
    weight: WeightUnit.POUND,
    length: LengthUnit.INCH,
    time: TimeUnit.HOUR,
    distance: DistanceUnit.MILES,
  },
};
