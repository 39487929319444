import { fromPairs, get, omit } from 'lodash-es';

import {
  fromServerDate,
  fromServerDateOptional,
  fromServerDateTime,
  fromServerDateTimeOptional,
  fromServerTime,
  joinDateAndTime,
} from '../../common/utils/dateUtils';
import { optionalDecorator } from '../../common/utils/funcUtils';
import { numberOr } from '../../common/utils/numberUtils';
import { trimWords } from '../../common/utils/stringUtils';
import { extractLabelFromRichFormValue } from '../../components/forms/dynamic/dynamicFormHelpers';
import { isFormSectionNonEmpty } from '../../components/forms/formHelpers';
import { richSelectValueToCodeValue } from '../../components/forms/selectUtils';
import { isSavedShipmentModeActive } from '../../forms/shipments/newShipment/savedShipmentsUtils';
import {
  extractActualWeight,
  getPackageCount,
  getPackageTotal,
  getValidPackages,
} from '../../forms/shipments/shipmentCommon';
import { ShipmentStage } from '../enums/ShipmentStage';
import { TransportType } from '../enums/TransportType';
import { normalizeAccount } from './accountConversions';
import {
  DeviceAddonType,
  getDeviceAddonLabel,
  mapDeviceAddonsInput,
} from './deviceAddonConversions';
import { graphQLToFormJobLocation } from './locationConversions';
import {
  NewShipmentDateSpecialValue,
  applyNewShipmentDateSpecialValue,
} from './newShipmentConversions';

export const graphqlToDetailDateTimeSchedule = optionalDecorator(schedule => ({
  scheduled: fromServerDateTimeOptional(schedule.scheduled),
  isAsap: !!schedule.isAsap,
  estimated: fromServerDateTimeOptional(schedule.estimated),
  actual: fromServerDateTimeOptional(schedule.actual),
}));

export function mapMetadata(metadata, { customerAccounts }) {
  if (!metadata) {
    return null;
  }

  const fullAccount =
    customerAccounts &&
    customerAccounts.find(acc => acc.number === metadata.qolAccount.number);
  const account = fullAccount || normalizeAccount(metadata.qolAccount);

  return {
    account,
    ...omit(metadata, ['qolAccount']),
  };
}

function graphqlToDetail(
  {
    metadata,
    jobNumber,
    origin,
    destination,
    shipmentStatus,
    stage,
    shippersReferences,
    pickupDateTime,
    deliveryDateTime,
    outForDeliveryDateTime,
    signedBy,
    productExpirationDateTime,
    indicators,
    serviceInformation,
    flights,
    packagesInformation,
    activity,
    driver,
    device,
    serviceUpdate,
    authorizedBy,
    documents,
    assets,
    unitSystem,
  },
  { customerAccounts } = {}
) {
  return {
    metadata: mapMetadata(metadata, { customerAccounts }),
    jobNumber,
    authorizedBy,
    origin,
    destination,
    status: shipmentStatus,
    stage,
    shipperReference: trimWords(shippersReferences || []).join('\n'),
    pickupDateTime: graphqlToDetailDateTimeSchedule(pickupDateTime),
    deliveryDateTime: graphqlToDetailDateTimeSchedule(deliveryDateTime),
    outForDeliveryDateTime: fromServerDateOptional(outForDeliveryDateTime),
    signedBy,
    productExpirationDateTime: fromServerDateOptional(
      productExpirationDateTime
    ),
    indicators,
    serviceInformation,
    flights: (flights || []).map(fl => ({
      ...fl,
      tenderDateTime: fromServerDateOptional(fl.tenderDateTime),
      recoveryDateTime: fromServerDateOptional(fl.recoveryDateTime),
      arrival: graphqlToDetailDateTimeSchedule(fl.arrival),
      departure: graphqlToDetailDateTimeSchedule(fl.departure),
    })),
    packagesInformation: packagesInformation && {
      ...packagesInformation,
      packages: packagesInformation.packages.map(pc => ({
        ...pc,
        packoutDateTime: fromServerDateTimeOptional(pc.packoutDateTime),
      })),
    },
    activity:
      activity &&
      activity.map(ev => ({
        type: ev.type,
        dateTime: fromServerDateTime(ev.dateTime),
      })),
    driver,
    device,
    serviceUpdate: serviceUpdate && {
      ...serviceUpdate,
      oldEstimatedArrival: fromServerDateTimeOptional(
        serviceUpdate.oldEstimatedArrival
      ),
      newEstimatedArrival: fromServerDateTimeOptional(
        serviceUpdate.newEstimatedArrival
      ),
    },
    documents: documents && {
      bolUrl: documents.bolUrl,
      documentList: documents.documentList?.map(({ uploadedAt, ...doc }) => ({
        ...doc,
        uploadedAt: fromServerDateTimeOptional(uploadedAt),
      })),
    },
    assets,
    unitSystem,
  };
}

export function formToDetailLocation({ companyName, address, contact } = {}) {
  return {
    companyName,
    address: {
      city: { text: address?.city },
      stateProvince: { text: address?.stateProvince },
      country: { text: address?.country },
      addressLine1: get(address, 'addressLines.0'),
      addressLine2: get(address, 'addressLines.1'),
      addressLine3: get(address, 'addressLines.2'),
      postalCode: address?.postalCode,
    },
    contact: {
      name: contact?.name,
      email: contact?.email,
      phone: contact?.phone,
    },
  };
}

function formToDetailPackage(pkg) {
  const { packoutDate, packoutTime, deviceAddons, ...rest } = pkg;

  return {
    ...rest,
    ...mapDeviceAddonsInput(deviceAddons),
  };
}

function formToDetailPackages(packages) {
  return {
    count: getPackageCount(packages, p => !p.exceptedQuantity),
    totalWeight: getPackageTotal(packages, extractActualWeight),
    packages,
  };
}

function formToDetail(values, { shipperSpecificInfoFields = [] } = {}) {
  const {
    jobNumber,
    account,
    origin,
    destination,
    serviceInformation,
    packageList,
    status,
    stage,
    unitSystem,
  } = values;

  const containsTbd = isSavedShipmentModeActive(values);
  return {
    containsTbd,
    metadata: { account },
    jobNumber: jobNumber || null,
    shipperReference: null,
    origin: formToDetailLocation(origin),
    destination: formToDetailLocation(destination),
    status: status || 'New',
    stage: stage || containsTbd ? ShipmentStage.SAVED : ShipmentStage.LIVE,
    pickupDateTime: {
      scheduled: origin.pickupDateTime,
      ...applyNewShipmentDateSpecialValue(
        origin['pickupDateTime-specialValue'],
        origin['pickupDateTime-specialValueData']
      ),
    },
    deliveryDateTime: {
      scheduled: destination.deliveryDateTime,
      ...applyNewShipmentDateSpecialValue(
        destination['deliveryDateTime-specialValue'],
        destination['deliveryDateTime-specialValueData']
      ),
    },
    serviceInformation: {
      // TODO - Right now we cannot determine this, but in the future there may be some way to do it
      transportType: TransportType.FLIGHT,
      commodity: get(serviceInformation, 'commodity'),
      serviceType: richSelectValueToCodeValue(
        get(serviceInformation, 'serviceType')
      ),
      bolNumber: get(serviceInformation, 'bolNumber'),
      dangerousGoods: get(serviceInformation, 'dangerousGoods'),
      exceptedQuantity: get(serviceInformation, 'exceptedQuantity'),
      unNumber: get(serviceInformation, 'unNumber'),
      currency: richSelectValueToCodeValue(get(serviceInformation, 'currency')),
      additionalInsurance: numberOr(
        parseFloat(get(serviceInformation, 'additionalInsurance')),
        null
      ),
      declaredValue: numberOr(
        parseFloat(get(serviceInformation, 'declaredValue')),
        null
      ),
      shipperSpecificInfo: shipperSpecificInfoFields.map(
        ({ name, label, type }) => ({
          label,
          value: extractLabelFromRichFormValue(
            serviceInformation?.shipperSpecificInfo?.[name]
          ),
          type,
        })
      ),
      eoriNumber: serviceInformation?.eoriNumber,
      hsInfo: serviceInformation?.hsInfo?.filter(item =>
        isFormSectionNonEmpty(item)
      ),
    },
    packagesInformation: packageList.tbd
      ? []
      : formToDetailPackages(
          getValidPackages(packageList.packages).map(formToDetailPackage)
        ),
    unitSystem,
  };
}

function graphQLToFormDeviceAddon(deviceAddon, type, index) {
  const text = getDeviceAddonLabel(deviceAddon);
  return {
    device: {
      code: deviceAddon.deviceCode,
      text,
    },
    type,
    brandName: deviceAddon.brandName,
    modelName: deviceAddon.modelName,
    serialNumber: deviceAddon.serialNumber,
    source: deviceAddon.source,
  };
}

function graphQLToFormPackage(pkg) {
  const { packoutDate, packoutTime, gpsDevices, temperatureDevices, ...rest } =
    pkg;

  return {
    ...rest,
    deviceAddons: [
      ...(pkg.gpsDevices?.map((da, i) =>
        graphQLToFormDeviceAddon(da, DeviceAddonType.GPS, i)
      ) || []),
      ...(pkg.temperatureDevices?.map((da, i) =>
        graphQLToFormDeviceAddon(da, DeviceAddonType.TEMPERATURE, i)
      ) || []),
    ],
    packoutDateTime:
      packoutDate &&
      packoutTime &&
      joinDateAndTime(fromServerDate(packoutDate), fromServerTime(packoutTime)),
  };
}

function graphQLToForm(values) {
  const {
    metadata,
    jobNumber,
    origin,
    pickupDateTime,
    destination,
    deliveryDateTime,
    serviceInformation,
    packagesInformation,
    status,
    stage,
    unitSystem,
  } = values;

  return {
    account: normalizeAccount(metadata.qolAccount),
    jobNumber,
    status,
    stage,
    origin: {
      ...graphQLToFormJobLocation(origin),
      pickupDateTime: fromServerDateTimeOptional(pickupDateTime.scheduled),
    },
    destination: {
      ...graphQLToFormJobLocation(destination),
      deliveryDateTime: fromServerDateTimeOptional(deliveryDateTime.scheduled),
      'deliveryDateTime-specialValue': deliveryDateTime.isAsap
        ? NewShipmentDateSpecialValue.ASAP
        : undefined,
    },
    serviceInformation: {
      ...serviceInformation,
      additionalInsurance:
        serviceInformation.additionalInsurance &&
        `${serviceInformation.additionalInsurance}`,
      declaredValue:
        serviceInformation.declaredValue &&
        `${serviceInformation.declaredValue}`,
      shipperSpecificInfo: fromPairs(
        serviceInformation.shipperSpecificInfo.map(({ name, value }) => [
          name,
          value,
        ])
      ),
    },
    packagesInformation: {
      ...packagesInformation,
      packages: getValidPackages(packagesInformation?.packages).map(
        graphQLToFormPackage
      ),
    },
    unitSystem,
  };
}

export const shipmentConversions = {
  graphqlToDetail,
  formToDetail,
  graphQLToForm,
};
