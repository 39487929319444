import { Col, Row } from 'antd';
import { ary, max } from 'lodash-es';
import { useIntl } from 'react-intl';

import { FATypes } from '../components/adapters/fontAwesomeAdapters';
import IconButton from '../components/buttons/IconButton';
import { FormItemOverrideContext } from '../components/forms/FormItem';
import FormList from '../components/forms/FormList';
import { FormItemInputText } from '../components/forms/basicFormElements';
import { useDynamicFormSchemaFieldDefinition } from '../components/forms/dynamic/dynamicFormSchema';
import { useDynamicFormValidationRules } from '../components/forms/dynamic/dynamicFormValidation';
import {
  EnsureInitialFormListItem,
  mergeFormNames,
} from '../components/forms/formHelpers';
import { FormItemSchemaDefinedSelect } from '../components/forms/selects';
import { useResponsiveQueries } from '../components/responsive/responsiveQueries';
import { cssVariables } from '../styles/cssVariables';
import { pxToNumber } from '../utils/cssUtils';

function AddressLine({ index, rules, add, remove, ...field }) {
  const intl = useIntl();
  const hasIcon = add || remove;
  const gutter = hasIcon ? pxToNumber(cssVariables.spaceNorm) : 0;

  return (
    <Row gutter={gutter} className="no-margin-form-items">
      <Col className="Flex1">
        <FormItemInputText
          label={`${intl.formatMessage({
            id: 'address.label.addressLine',
          })} ${index + 1}`}
          rules={rules}
          {...field}
        />
      </Col>
      <FormItemOverrideContext.Consumer>
        {({ disabled, viewMode }) =>
          !viewMode && (
            <Col
              className="flex-center spaces-hor-norm"
              style={{ height: cssVariables['input-height-md'] }}
            >
              {remove && (
                <IconButton
                  icon="times-circle"
                  type={FATypes.SOLID}
                  className="AddressRowIcon"
                  onClick={remove}
                  disabled={disabled}
                />
              )}
              {add && (
                <IconButton
                  icon="plus-square"
                  type={FATypes.SOLID}
                  className="AddressRowIcon"
                  onClick={add}
                  disabled={disabled}
                />
              )}
            </Col>
          )
        }
      </FormItemOverrideContext.Consumer>
    </Row>
  );
}

function renderAddressLines(
  fields,
  { add, remove },
  { maxLines, numRequired, linesRules }
) {
  return (
    <>
      <EnsureInitialFormListItem
        fields={fields}
        add={add}
        value=""
        initialCount={Math.max(1, numRequired)}
      />
      {fields.map((field, i) => {
        const canAdd = i === fields.length - 1 && fields.length < maxLines;
        const canRemove = i > numRequired - 1;

        return (
          <AddressLine
            {...field}
            add={canAdd && ary(add, 0)}
            remove={canRemove && (() => remove(i))}
            rules={linesRules[i]}
            index={i}
          />
        );
      })}
    </>
  );
}

const getAddressLineSchemaName = (prefix, number) =>
  `${prefix}.addressLine${number}`;
function AddressLines({ namePrefix, schemaNamePrefix }) {
  const line1Schema = useDynamicFormSchemaFieldDefinition({
    schemaName: getAddressLineSchemaName(schemaNamePrefix, 1),
  });
  const line2Schema = useDynamicFormSchemaFieldDefinition({
    schemaName: getAddressLineSchemaName(schemaNamePrefix, 2),
  });
  const line3Schema = useDynamicFormSchemaFieldDefinition({
    schemaName: getAddressLineSchemaName(schemaNamePrefix, 3),
  });
  const line1Rules = useDynamicFormValidationRules({
    schemaName: getAddressLineSchemaName(schemaNamePrefix, 1),
    validation: line1Schema.validation,
  });
  const line2Rules = useDynamicFormValidationRules({
    schemaName: getAddressLineSchemaName(schemaNamePrefix, 2),
    validation: line2Schema.validation,
  });
  const line3Rules = useDynamicFormValidationRules({
    schemaName: getAddressLineSchemaName(schemaNamePrefix, 3),
    validation: line3Schema.validation,
  });
  const numRequired = max(
    [line1Schema, line2Schema, line3Schema].map((sch, i) =>
      sch.validation.rules.required ? i + 1 : 0
    )
  );

  return (
    <FormList name={mergeFormNames(namePrefix, 'addressLines')}>
      {(fields, controls) =>
        renderAddressLines(fields, controls, {
          maxLines: 3,
          numRequired,
          linesRules: [line1Rules, line2Rules, line3Rules],
        })
      }
    </FormList>
  );
}

export function AddressCountryFormItem({
  parentName,
  parentSchemaName,
  disabled,
}) {
  return (
    <FormItemSchemaDefinedSelect
      allowFreeText
      textAsValue
      allowServerSideSearch
      name={mergeFormNames(parentName, 'country')}
      schemaName={`${parentSchemaName}.country`}
      labelId="address.label.country"
      disabled={disabled}
    />
  );
}

function StateFormItem({ parentName, parentSchemaName }) {
  return (
    <FormItemInputText
      name={mergeFormNames(parentName, 'stateProvince')}
      schemaName={`${parentSchemaName}.stateProvince`}
      labelId="address.label.state"
    />
  );
}

function PostalCodeFormItem({ parentName, parentSchemaName }) {
  return (
    <FormItemInputText
      name={mergeFormNames(parentName, 'postalCode')}
      schemaName={`${parentSchemaName}.postalCode`}
      labelId="address.label.zip"
      className="ZipCodeInput"
    />
  );
}

function CityFormItem({ parentName, parentSchemaName, makeSelectHandler }) {
  return (
    <FormItemInputText
      name={mergeFormNames(parentName, 'city')}
      schemaName={`${parentSchemaName}.city`}
      labelId="address.label.city"
    />
  );
}

export function AddressFormItems({ name, schemaName }) {
  const media = useResponsiveQueries();

  const addressFormItemProps = {
    parentName: name,
    parentSchemaName: schemaName,
  };

  if (media.xxl || media.xl) {
    return (
      <>
        <AddressLines namePrefix={name} schemaNamePrefix={schemaName} />
        <Row
          align="top"
          gutter={pxToNumber(cssVariables.spaceNorm2)}
          className="no-margin-form-items"
        >
          <Col>
            <PostalCodeFormItem {...addressFormItemProps} />
          </Col>
          <Col flex="1">
            <CityFormItem {...addressFormItemProps} />
          </Col>
          <Col flex="0.36">
            <StateFormItem {...addressFormItemProps} />
          </Col>
        </Row>
      </>
    );
  }

  if (media.lg || media.md) {
    return (
      <>
        <AddressLines namePrefix={name} schemaNamePrefix={schemaName} />
        <Row
          align="top"
          gutter={pxToNumber(cssVariables.spaceNorm)}
          className="no-margin-form-items"
        >
          <Col>
            <PostalCodeFormItem {...addressFormItemProps} />
          </Col>
          <Col flex="1">
            <CityFormItem {...addressFormItemProps} />
          </Col>
          <Col flex="0.67">
            <StateFormItem {...addressFormItemProps} />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <AddressLines namePrefix={name} schemaNamePrefix={schemaName} />
      <Row align="top" gutter={pxToNumber(cssVariables.spaceNorm)}>
        <Col>
          <PostalCodeFormItem {...addressFormItemProps} />
        </Col>
        <Col className="Flex1">
          <CityFormItem {...addressFormItemProps} />
        </Col>
      </Row>
      <div className="no-margin-form-items">
        <StateFormItem {...addressFormItemProps} />
      </div>
    </>
  );
}
