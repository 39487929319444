import { message } from 'antd';

export const showQuoteMessage = (formatMessage, onClose) => {
  message.warning({
    content: (
      <div className="quotes-notification-content">
        {formatMessage({ id: 'book.newQuote.warning-restriction' })}
        <br />
        {formatMessage({ id: 'book.newQuote.warning-contact' })}
        <a
          href="https://quick.aero/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          <u>
            {formatMessage({
              id: 'book.newQuote.warning-customer-service',
            })}
          </u>
        </a>
      </div>
    ),
    className: 'quotes-notification',
    duration: 10,
    onClose,
  });
};
