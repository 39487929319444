import { useQuery } from '@apollo/client';
import { Button, Col, Row, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';

import { formToInputJobLocation } from '../../app/data/locationConversions';
import { ADDRESS_BOOK_ENTRIES_QUERY } from '../../app/graphql/addressBookQueries';
import { useReduxDialogControls } from '../../common/utils/dialogUtils';
import { FAIcon, FATypes } from '../../components/adapters/fontAwesomeAdapters';
import { useDynamicOriginalValueDependency } from '../../components/forms/dynamic/dynamicFormDependencies';
import { isFormSectionNonEmpty } from '../../components/forms/formHelpers';
import { useFormContext } from '../../components/forms/forms';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import AddressBookDrawer from './AddressBookDrawer';

function useEditableButtonSetting(account) {
  const { data: addressBookData } = useQuery(ADDRESS_BOOK_ENTRIES_QUERY, {
    variables: { accountNumber: account?.number },
    skip: !account,
  });

  return addressBookData?.addressBookEntries?.enableEditButton;
}

export default function AddressBookControls({
  name,
  namePrefix,
  type,
  countryCodeRestrict,
}) {
  const { isOpen, open, close, data, setData } = useReduxDialogControls({
    id: 'newShipment.addressBook',
    spec: { name, type },
  });
  const { values } = useFormContext();
  const formValues = namePrefix ? values[namePrefix] || {} : values;
  const isNonEmpty = isFormSectionNonEmpty(formToInputJobLocation(formValues));
  const account = useDynamicOriginalValueDependency('account');

  const enableEditButton = useEditableButtonSetting(account);
  const editable = !account?.partner || enableEditButton;

  return (
    <div className="AddressBook-ControlLinks">
      <Row
        className="flex-nowrap"
        align="middle"
        gutter={pxToNumber(cssVariables.spaceNorm2)}
      >
        {editable && (
          <Col>
            <Tooltip
              title={
                <FormattedMessage id="addressBook.tooltip.addNewContact" />
              }
            >
              <Button
                className="width-50"
                size="small"
                onClick={() => open({ adding: true })}
                disabled={!isNonEmpty}
                tabIndex="-1"
                data-subject="address-book"
                data-action="add"
              >
                <FAIcon
                  icon="address-card"
                  className="icon-15"
                  type={FATypes.REGULAR}
                />
                <FAIcon icon="plus" className="icon-11" />
              </Button>
            </Tooltip>
          </Col>
        )}
        <Col>
          <Tooltip title={<FormattedMessage id="addressBook.tooltip.open" />}>
            <Button
              className="width-70"
              size="small"
              onClick={open}
              tabIndex="-1"
              data-subject="address-book"
              data-action="open"
            >
              <FAIcon
                icon="address-book"
                className="icon-15"
                type={FATypes.REGULAR}
              />
              <span>
                <FormattedMessage id="buttons.open" />
              </span>
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <AddressBookDrawer
        visible={isOpen}
        open={open}
        onClose={close}
        data={data}
        setData={setData}
        readOnly={!editable}
        countryCodeRestrict={countryCodeRestrict}
        namePrefix={namePrefix}
      />
    </div>
  );
}
