import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { cloneDeep, debounce, get, set, some } from 'lodash-es';
import URI from 'urijs';

import { ErrorCode } from '../app/common/ErrorCode';
import { getLoggedIn, logoutUser } from '../app/redux/auth';
import { openDialog } from '../app/redux/dialogs';
import {
  mergeArraysByIndices,
  mergePaginatedGraphqlResult,
} from '../common/utils/graphqlUtils';
import { getIntlFromStore } from '../utils/intlUtils';
import { log } from '../utils/logger';
import { showError } from '../utils/messageUtils';
import { makeBaseHttpHeaders } from './httpHeaders';

const GraphQLError = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

export const httpLink = createHttpLink({
  // It's always the same domain, server ensures that it's proxied to the correct place
  uri: '/graphql',
});

const wsLink = new WebSocketLink({
  uri: URI()
    .protocol(URI().protocol() === 'https' ? 'wss' : 'ws')
    .path('graphql')
    .toString(),
  options: {
    lazy: true,
    reconnect: true,
    timeout: 60000,
    connectionParams: () => ({ headers: makeBaseHttpHeaders() }),
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

function hideInputSecrets(variables) {
  return [
    'input.password',
    'input.pin',
    'password',
    'pin',
    'authToken',
    'token',
  ].reduce((output, secretPath) => {
    if (get(output, secretPath)) {
      return set(cloneDeep(output), secretPath, '***');
    }
    return output;
  }, variables);
}

export const authLink = setContext((request, { headers, ...other }) => {
  const baseHeaders = makeBaseHttpHeaders();
  log.debug(
    `txref: ${baseHeaders.txref}, operation: ${request.operationName}`,
    hideInputSecrets(request.variables)
  );
  return {
    headers: {
      ...headers,
      ...baseHeaders,
      // Prefer auth token passed directly (to support various "virtual" login situations)
      'auth-token': headers?.['auth-token'] || baseHeaders['auth-token'],
    },
  };
});

export function makeApolloClient() {
  // store dependency should be initialized as soon as available
  let store;

  // If this happens, there are usually multiple request failing close to each other
  // and we don't want to show multiple error messages
  const debouncedShowError = debounce(
    contentId => showError({ contentId }, { intl: getIntlFromStore(store) }),
    500
  );

  const logoutLink = onError(({ graphQLErrors }) => {
    const loggedIn = getLoggedIn(store.getState());
    const isUnauthenticated = some(
      graphQLErrors || [],
      err => get(err, 'extensions.code') === GraphQLError.UNAUTHENTICATED
    );
    if (isUnauthenticated && loggedIn) {
      debouncedShowError('error.tokenExpired');
      store.dispatch(logoutUser());
    }
  });

  const versionCompatibilityLink = onError(({ graphQLErrors }) => {
    const isIncompatibleVersion = some(
      graphQLErrors || [],
      err =>
        get(err, 'extensions.exception.code') === ErrorCode.INCOMPATIBLE_VERSION
    );
    if (isIncompatibleVersion) {
      store.dispatch(openDialog({ id: 'app.incompatbileVersion' }));
    }
  });

  const errorLink = onError(event => {
    const { operation } = event;
    const ctxt = operation.getContext();
    const txref = ctxt?.response?.headers?.get('txref') || ctxt?.headers?.txref;
    const errorsInfo = get(event, 'graphQLErrors.0.extensions.apiErrors') ||
      get(event, 'graphQLErrors') || [event];
    log.error(
      `txref: ${txref}, operation: ${operation.operationName}`,
      ...errorsInfo
    );
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          customerShipments: {
            keyArgs: ['filter', 'sort'],
            read: existing => existing || {},
            merge: (existing, incoming, { mergeObjects }) =>
              mergeObjects(existing, incoming),
          },
          customerQuotes: {
            keyArgs: ['filter', 'sort'],
            read: existing => existing || {},
            merge: (existing, incoming, { mergeObjects }) =>
              mergeObjects(existing, incoming),
          },
          users: {
            keyArgs: ['filter', 'sort'],
            read: existing => existing || {},
            merge: (existing, incoming, { mergeObjects }) =>
              mergeObjects(existing, incoming),
          },
          accounts: {
            keyArgs: ['filter', 'sort'],
            read: existing => existing || {},
            merge: (existing, incoming, { mergeObjects }) =>
              mergeObjects(existing, incoming),
          },
          shipmentFilters: {
            keyArgs: ['sort'],
          },
          customerShipmentTemplates: {
            keyArgs: ['accountNumber', 'sort'],
          },
        },
      },
      QuotesResult: {
        fields: {
          data: {
            merge: mergePaginatedGraphqlResult,
          },
        },
      },
      ShipmentsResult: {
        fields: {
          data: {
            merge: mergePaginatedGraphqlResult,
          },
        },
      },
      UsersResult: {
        fields: {
          data: {
            merge: mergePaginatedGraphqlResult,
          },
        },
      },
      AccountsResult: {
        fields: {
          data: {
            merge: mergePaginatedGraphqlResult,
          },
        },
      },
      Shipment: {
        keyFields: ['jobNumber'],
        merge: true,
        fields: {
          pickupDateTime: { merge: true },
          deliveryDateTime: { merge: true },
          origin: { merge: true },
          destination: { merge: true },
          packagesInformation: {
            merge: (existing, incoming, { mergeObjects }) => {
              if (!incoming && !existing) {
                return null;
              }

              const { packages: existingPackages = [], ...existingRest } =
                existing || {};
              const { packages: incomingPackages = [], ...incomingRest } =
                incoming || {};

              return {
                packages: mergeArraysByIndices(
                  existingPackages,
                  incomingPackages
                ),
                ...mergeObjects(existingRest, incomingRest),
              };
            },
          },
          serviceUpdate: { merge: true },
          assets: { merge: false },
        },
      },
      QuoteResult: {
        keyFields: ['quoteNumber'],
        merge: true,
      },
      Account: {
        keyFields: ['number'],
        merge: true,
      },
    },
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      logoutLink,
      versionCompatibilityLink,
      errorLink,
      authLink,
      splitLink,
    ]),
    cache,
  });

  // enhancement making store available for the client
  apolloClient.setReduxStore = reduxStore => {
    store = reduxStore;
  };
  return apolloClient;
}
