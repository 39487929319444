import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { BarcodeOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { useLocation } from 'react-router-dom';

import { Flex1, TitleWithExtra } from '../components/layout/layoutElements';
import { JobLocationSection } from '../forms/shipments/newShipment/newShipmentElements';
import { LINK_JOB_TO_DEVICE_MUTATION } from '../app/graphql/jobFormQueries';
import ProcessingModal from '../components/dialogs/ProcessingModal';
import { OrderSuccessDialog as SuccessDialog } from '../dialogs/shipments/newShipmentDialogs';
import { SimpleConfirmDialog } from '../components/dialogs/SimpleConfirmDialog';

export default function LinkDeviceToJobPage() {
  const [mutate, { loading }] = useMutation(LINK_JOB_TO_DEVICE_MUTATION);
  const [form, setForm] = useState({
    deviceType: 'ROAMBEE',
    bol: undefined,
    deviceId: undefined,
  });
  const [errors, setErrors] = useState({
    bol: false,
    deviceId: false,
  });
  const [context, setContext] = useState({
    bol: undefined,
    jobNumber: undefined,
    clientToken: undefined,
  });
  const [isBOLMatching, setIsBOLMatching] = useState(true);
  const [success, setSuccess] = useState(undefined);
  const [showCamera, setShowCamera] = useState(false);
  const [field, setField] = useState('');
  const { formatMessage: f } = useIntl();
  const location = useLocation();

  const deviceTypeOptions = [
    { label: f({ id: 'linkDeviceToJob.roambeeLabel' }), value: 'ROAMBEE' },
    { label: f({ id: 'linkDeviceToJob.tiveDevice' }), value: 'TIVE' },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const bolNumber = queryParams.get('bol');
    const jobNumber = queryParams.get('jobNumber');
    const clientToken = queryParams.get('clientToken');

    setContext({
      bol: bolNumber,
      jobNumber,
      clientToken,
    });
  }, [location.search]);

  const getSuffix = scannedField => (
    <BarcodeOutlined
      onClick={() => {
        setField(scannedField);
        setShowCamera(!showCamera);
      }}
    />
  );

  const reset = () => {
    setForm({
      deviceType: 'ROAMBEE',
      bol: undefined,
      deviceId: undefined,
    });
    setField('');
    setShowCamera(false);
    setErrors({
      bol: false,
      deviceId: false,
    });
    setIsBOLMatching(true);
  };

  const onSubmit = async () => {
    const { bol, deviceId } = form || {};

    if (!bol || !deviceId) {
      setErrors({
        bol: !bol,
        deviceId: !deviceId,
      });
      return;
    }

    if (context?.bol !== bol) {
      setIsBOLMatching(false);
      setErrors({
        ...errors,
        bol: true,
      });
      return;
    }

    const input = {
      jobNumber: form?.bol,
      deviceType: form?.deviceType,
      deviceModel: form?.deviceType,
      deviceId: form?.deviceId,
      clientToken: context?.clientToken,
    };

    try {
      const { data } = await mutate({ variables: { input } });
      const { linkJobToDevice } = data || {};
      reset();
      setSuccess(linkJobToDevice === true);
    } catch (e) {
      setSuccess(false);
    }
  };

  return (
    <Flex1 className="link-device-to-job-page">
      <TitleWithExtra titleId="linkDeviceToJob.title" />

      <div className="job-details">
        <span>
          <FormattedMessage id="linkDeviceToJob.jobNumber" />
        </span>
        <span>{context?.jobNumber}</span>
      </div>

      <JobLocationSection titleId="linkDeviceToJob.deviceType">
        <Select
          disabled
          value={form?.deviceType}
          onChange={value => setForm({ ...form, deviceType: value })}
          options={deviceTypeOptions}
        />
      </JobLocationSection>

      <JobLocationSection titleId="linkDeviceToJob.bol">
        <Input
          onChange={event => setForm({ ...form, bol: event.target.value })}
          value={form.bol}
          status={errors?.bol ? 'error' : ''}
          suffix={getSuffix('bol')}
        />
      </JobLocationSection>

      <JobLocationSection titleId="linkDeviceToJob.deviceId">
        <Input
          onChange={event => setForm({ ...form, deviceId: event.target.value })}
          value={form.deviceId}
          status={errors?.deviceId ? 'error' : ''}
          suffix={getSuffix('deviceId')}
        />
      </JobLocationSection>

      {showCamera && (
        <BarcodeScannerComponent
          onUpdate={(_err, result) => {
            if (result) {
              setShowCamera(false);
              console.log(result.getText());
              setForm({
                ...form,
                [field]: result.getText(),
              });
            }
          }}
        />
      )}

      <Button type="primary" onClick={onSubmit}>
        <FormattedMessage id="buttons.save" />
      </Button>

      {loading && (
        <ProcessingModal textId="linkDeviceToJob.linking-job-to-device" />
      )}

      {success && (
        <SuccessDialog
          titleId="linkDeviceToJob.successDialog.title"
          subTitleId="linkDeviceToJob.successDialog.subtitle"
          onOk={() => setSuccess(undefined)}
        />
      )}

      <SimpleConfirmDialog
        visible={success === false}
        onClose={() => setSuccess(undefined)}
        cancelTextId="buttons.close"
        textId="linkDeviceToJob.errorDialog.message"
        centered
        mask
      />

      <SimpleConfirmDialog
        visible={!isBOLMatching}
        onClose={() => setIsBOLMatching(true)}
        cancelTextId="buttons.close"
        textId="linkDeviceToJob.BOLMatching.message"
        centered
        mask
      />
    </Flex1>
  );
}
