import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import { isNumber, property } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { WeightUnit } from '../../../app/enums/measuringUnits';
import { useEffectDebounced } from '../../../common/utils/hookUtils';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import { ThreeDotsSpinner } from '../../../components/data/Spinner';
import { useFlashMessageContext } from '../../../components/dialogs/FlashMessageProvider';
import {
  FormItemInputNumericDuration,
  FormItemInputWeight,
  WeightFormat,
} from '../../../components/domainSpecific/measuringUnitsElements';
import { makeRulesReplacingDecorator } from '../../../components/forms/FormItem';
import { FormItemFieldRegistration } from '../../../components/forms/basicFormElements';
import { FormItemInlineCheckbox } from '../../../components/forms/checkable';
import { FormItemDateTimePicker } from '../../../components/forms/dateElements';
import { useDynamicOriginalValueDependency } from '../../../components/forms/dynamic/dynamicFormDependencies';
import { useDynamicFormSchemaFieldDefinition } from '../../../components/forms/dynamic/dynamicFormSchema';
import { useDynamicFormValidationRules } from '../../../components/forms/dynamic/dynamicFormValidation';
import { useValidationAfterChange } from '../../../components/forms/formHelpers';
import { useFormContext } from '../../../components/forms/forms';
import {
  FormItemSchemaDefinedSelect,
  FormItemSelect,
} from '../../../components/forms/selects';
import {
  ThreeLines,
  TwoLines,
  TwoLinesSeparator,
} from '../../../components/layout/layoutElements';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { LabelWithValue } from '../../../components/typography';
import { FEATURE_FLAGS } from '../../../config/featureFlags';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { useMountedIndicator } from '../../../utils/reactUtils';
import { useShipmentMode } from '../shipmentModes';
import { PackageListDeviceAddonsField } from './PackageListDeviceAddon';
import { PackageListDimensionsInputs } from './PackageListDimensionsInputs';
import { PackageListPieceListSection } from './PackageListPieceList';
import {
  DividerCol,
  PackageCard,
  PackageListCardHeader,
  PackageListCardSection,
  PackageListCardSubsection,
  PackageListCardSubtitle,
  SymbolCol,
  canComputeDimensionalWeight,
  isPackoutDateTimeEnabled,
  useComputeDimensionalWeight,
  usePackageListResponsiveQueries,
  useServiceInformationDeps,
} from './packageListCommon';
import { usePackageListFieldVisibilityContext } from './packageListFieldVisibility';

const RowLvl1 = props => (
  <Row {...props} align="top" gutter={pxToNumber(cssVariables.spaceNorm2)} />
);
const RowLvl2 = props => (
  <Row {...props} align="top" gutter={pxToNumber(cssVariables.spaceNorm)} />
);

function PackageDescriptionSection({ showTbd, tbdValue, onTbdChange }) {
  const { hasTemperature } = useShipmentMode();
  const lgColSize = hasTemperature ? 8 : 12;
  const colProps = {
    xxl: lgColSize,
    xl: lgColSize,
    lg: lgColSize,
    md: 12,
    sm: 24,
    xs: 24,
  };

  return (
    <>
      <PackageListCardSection
        titleId="book.newShipment.packages.packageDescription"
        noTopLines
      >
        {showTbd && (
          <div className="NewShipmentPackage-TBDCheckbox">
            <FormItemInlineCheckbox
              labelId="labels.tbd.saveShipment"
              formItemComponentProps={{
                checked: tbdValue,
                onChange: e => onTbdChange(e.target.checked),
              }}
            />
          </div>
        )}
        <RowLvl1 className="NewShipmentPackage-PackageDescriptionRow">
          {hasTemperature && (
            <Col {...colProps}>
              <FormItemSchemaDefinedSelect
                name="commodityTemperature"
                labelId="book.newShipment.label.commodityTemperature"
                schemaName="packages.commodityTemperature"
              />
            </Col>
          )}
          <Col {...colProps}>
            <FormItemSchemaDefinedSelect
              name="packageSource"
              schemaName="packages.packageSource"
              labelId="book.newShipment.label.packageSource"
            />
          </Col>
          <Col {...colProps}>
            <FormItemSchemaDefinedSelect
              name="packagingType"
              labelId="book.newShipment.label.packagingType"
              schemaName="packages.packagingType"
              allowFreeText
            />
          </Col>
        </RowLvl1>
      </PackageListCardSection>
    </>
  );
}

export const WeightInput = props => (
  <FormItemInputWeight
    name="actualWeight"
    labelId="book.newShipment.label.actualWeight.short"
    schemaName="packages.actualWeight"
    {...props}
  />
);

const EQCol = props => (
  <SymbolCol {...props}>
    <div className="DimensionEquals">
      <FAIcon icon="equals" />
    </div>
  </SymbolCol>
);
const DIM_WEIGHT_EMPTY = {
  dimensionalWeight: null,
  billedWeight: null,
};
export function DimensionalWeightResult({
  index,
  separator,
  labelId = 'book.newShipment.label.dimensionalWeightResult',
  onLoadingChange,
}) {
  const mounted = useMountedIndicator();
  const computeDimensionalWeight = useComputeDimensionalWeight();
  const { errorMessage } = useFlashMessageContext();
  const { values, formInstance, forceUpdate, parentContext } = useFormContext();
  const { actualWeight, dimensionalWeight, height, length, width } = values;
  const [loading, setLoading] = useState();

  const setLoadingAndValues = (newLoading, vals) => {
    if (mounted.current) {
      setLoading(newLoading);
      formInstance.setFieldsValue(vals);
      forceUpdate();

      if (onLoadingChange) {
        onLoadingChange(newLoading);
      }
    } else if (parentContext) {
      // If this is unmounted, it means that editing was finished and a temporary form doesn't exist -
      // we must modify the whole package array directly
      const { packages, ...otherValues } =
        parentContext.formInstance.getFieldsValue();
      const thisPackage = packages[index];
      const newPackages = [...packages];
      newPackages[index] = {
        ...thisPackage,
        ...vals,
      };
      parentContext.formInstance.setFieldsValue({
        packages: newPackages,
        ...otherValues,
      });
      parentContext.forceUpdate();
    }
  };

  const startLoading = () => setLoadingAndValues(true, DIM_WEIGHT_EMPTY);
  const clearValues = () => {
    // Clearing is the result of the last change, any running promise must be invalidated
    lastLoad.current = undefined;
    setLoadingAndValues(false, DIM_WEIGHT_EMPTY);
  };
  const stopLoading = vals => setLoadingAndValues(false, vals);

  // This contains the last loading - it's to prevent setting the values that weren't a result
  // of the last form change
  const lastLoad = useRef();
  useEffectDebounced(
    () => {
      if (!mounted.current) {
        return;
      }

      const loadDimensionalWeight = async () => {
        startLoading();

        const promise = computeDimensionalWeight({
          actualWeight,
          height,
          length,
          width,
        });
        lastLoad.current = promise;
        try {
          const newComputedValues = await promise;
          if (lastLoad.current !== promise) {
            return;
          }
          stopLoading(newComputedValues);
        } catch (e) {
          clearValues();
          errorMessage(e);
        }
      };

      const canCompute = canComputeDimensionalWeight({
        actualWeight,
        height,
        length,
        width,
      });

      if (canCompute) {
        loadDimensionalWeight();
      } else {
        clearValues();
      }
    },
    250,
    [actualWeight, height, length, width]
  );

  return (
    <>
      <FormItemFieldRegistration name="dimensionalWeight" />
      <FormItemFieldRegistration name="billedWeight" />
      <div className="DimensionalWeightResultLabel">
        <FormattedMessage id={labelId} />
      </div>
      {separator && (
        <div className="DimensionalWeightResultSeparator">{separator}</div>
      )}
      <div
        className={classNames('DimensionalWeightResult', {
          'no-value': !isNumber(dimensionalWeight),
        })}
      >
        {loading ? (
          <ThreeDotsSpinner />
        ) : (
          <WeightFormat value={dimensionalWeight} />
        )}
      </div>
    </>
  );
}

function UnknownWeightInput() {
  useValidationAfterChange({
    dependencyName: 'unknownWeight',
    dependentNames: ['actualWeight', 'length', 'width', 'height'],
  });

  return (
    <FormItemInlineCheckbox
      name="unknownWeight"
      labelId="book.newShipment.label.unknownWeight"
    />
  );
}

function WeightBasicInfoRow() {
  const { isLg } = usePackageListResponsiveQueries();
  const { values } = useFormContext();

  if (!FEATURE_FLAGS.unknownWeight && !FEATURE_FLAGS.vehicleType) {
    return null;
  }

  return (
    <Row
      className="NewShipmentPackage-WeightCalculationRow"
      align="middle"
      justify="space-between"
      gutter={isLg ? pxToNumber(cssVariables.spaceLg) : 0}
    >
      {FEATURE_FLAGS.unknownWeight && (
        <Col>
          <UnknownWeightInput />
        </Col>
      )}
      {FEATURE_FLAGS.vehicleType && (
        <>
          {isLg && (
            <DividerCol
              className="NewShipmentPackage-VehicleType-Inactive"
              smallVerticalSpace
            />
          )}
          <Col
            className={classNames('NewShipmentPackage-VehicleType', {
              Flex1: isLg,
            })}
          >
            {isLg && (
              <span
                className={classNames('label', {
                  DisabledBlock: !values.unknownWeight,
                  'DisabledBlock--50': !values.unknownWeight,
                })}
              >
                <FormattedMessage id="book.newShipment.label.vehicleType.long" />
              </span>
            )}
            <FormItemSelect
              name="vehicleType"
              labelId={
                isLg
                  ? 'book.newShipment.label.vehicleType'
                  : 'book.newShipment.label.vehicleType.medium'
              }
              disabled={!values.unknownWeight}
              options={[{ label: 'Truck', value: 'TRUCK' }]}
            />
          </Col>
        </>
      )}
    </Row>
  );
}

function WeightDimsRowLg({ index, inputProps }) {
  return (
    <Row align="top" gutter={pxToNumber(cssVariables.spaceSm)}>
      <Col className="Flex1">
        <WeightInput {...inputProps} />
      </Col>
      <SymbolCol className="DimensionSeparator">
        <FAIcon icon="plus-square" className="icon-22" />
      </SymbolCol>
      <PackageListDimensionsInputs index={index} inputProps={inputProps} />
      <EQCol />
      <Col>
        <DimensionalWeightResult index={index} />
      </Col>
    </Row>
  );
}

function WeightDimsRowSm({ index, inputProps }) {
  const media = useResponsiveQueries();
  const symbolWidth = media.xs ? 25 : 32;
  return (
    <>
      <Row align="top">
        <PackageListDimensionsInputs
          index={index}
          inputColProps={{ flex: '3' }}
          symbolColProps={{ style: { width: symbolWidth } }}
          inputProps={inputProps}
        />
      </Row>
      <Row align="top">
        <Col flex="3">
          <WeightInput index={index} {...inputProps} />
        </Col>
        <Col style={{ width: symbolWidth }} />
        {/* This must be aligned with "width" field -> the column above is a filler to ensure that */}
        <EQCol style={{ justifyContent: 'flex-start', width: symbolWidth }} />
        <Col flex="6">
          <DimensionalWeightResult index={index} />
        </Col>
      </Row>
    </>
  );
}

function WeightDimsRow({ index }) {
  const { isLg } = usePackageListResponsiveQueries();
  const { values } = useFormContext();
  const inputProps = values.unknownWeight
    ? {
        disabled: true,
        rules: makeRulesReplacingDecorator({
          predicate: property('required'),
          replacement: { required: false },
        }),
      }
    : {};

  return isLg ? (
    <WeightDimsRowLg index={index} inputProps={inputProps} />
  ) : (
    <WeightDimsRowSm index={index} inputProps={inputProps} />
  );
}

function WeightSection({ index }) {
  const { isSm, isLg } = usePackageListResponsiveQueries();

  return (
    <PackageListCardSection
      className="merge-with-prev"
      titleId={isSm && 'book.newShipment.packages.weight'}
    >
      {isSm && <WeightBasicInfoRow />}
      <PackageListCardSubsection titleId="book.newShipment.packages.weightCalculation">
        <div className="spaces-vert-md">
          {isLg && <WeightBasicInfoRow />}
          <WeightDimsRow index={index} />
        </div>
      </PackageListCardSubsection>
    </PackageListCardSection>
  );
}

function RequiredLabel({ required }) {
  return required ? ' *' : null;
}
function PkgQualTimeSelect() {
  const { formInstance, forceUpdate, values } = useFormContext();
  const onAutofill = useCallback(
    val => {
      formInstance.setFieldsValue({
        'packageQualificationTime-autofilled': !!val,
      });
      forceUpdate();
    },
    [forceUpdate, formInstance]
  );
  const onAutofillSkip = useCallback(() => {
    formInstance.setFieldsValue({
      'packageQualificationTime-autofilled': false,
    });
    forceUpdate();
  }, [forceUpdate, formInstance]);
  const disabled = values['packageQualificationTime-autofilled'];

  return (
    <>
      <FormItemInputNumericDuration
        name="packageQualificationTime"
        schemaName="packages.packageQualificationTime"
        labelId="book.newShipment.label.packageQualificationTime"
        formItemComponentProps={{ onAutofill, onAutofillSkip }}
        disabled={disabled}
      />
      <FormItemFieldRegistration name="packageQualificationTime-autofilled" />
    </>
  );
}
const PackoutDTPicker = ({ schemaName, name }) => {
  const { formInstance, forceUpdate } = useFormContext();
  const fieldDef = useDynamicFormSchemaFieldDefinition({
    schemaName,
  });
  const rules = useDynamicFormValidationRules({
    schemaName,
    validation: fieldDef.validation,
  });
  const packageSource = useDynamicOriginalValueDependency(
    'packages.packageSource'
  );
  const disabled = !isPackoutDateTimeEnabled(packageSource);
  useEffect(() => {
    if (disabled) {
      formInstance.setFieldsValue({ packoutDateTime: null });
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <FormItemDateTimePicker
      name={name}
      expandSize
      rules={rules}
      disabled={disabled}
    />
  );
};
function TimingSubsection() {
  const { packoutDateTime } = usePackageListFieldVisibilityContext();
  const packoutFieldDef = useDynamicFormSchemaFieldDefinition({
    schemaName: 'packages.packoutDateTime',
  });
  const packoutRequired = !!packoutFieldDef.validation?.rules?.required;

  return (
    <>
      <RowLvl1 className="hide-md-and-smaller">
        <Col span={packoutDateTime ? 12 : 24}>
          <PackageListCardSubtitle textId="book.newShipment.packages.packageQualificationTime" />
          <PkgQualTimeSelect />
        </Col>
        {packoutDateTime && (
          <>
            <DividerCol smallVerticalSpace />
            <Col span={12}>
              {/* DateTime picker classes are used to ensure proper aligning */}
              <RowLvl1>
                <Col span={24} className="DateTimePicker expand-size">
                  <div className="Col-DatePicker">
                    <PackageListCardSubtitle
                      textId="book.newShipment.packages.packoutDate"
                      requiredIndicator={packoutRequired}
                    />
                  </div>
                  <div className="Col-TimePicker">
                    <PackageListCardSubtitle
                      textId="book.newShipment.packages.packoutTime"
                      requiredIndicator={packoutRequired}
                    />
                  </div>
                </Col>
              </RowLvl1>
              <PackoutDTPicker
                name="packoutDateTime"
                schemaName="packages.packoutDateTime"
              />
            </Col>
          </>
        )}
      </RowLvl1>
      <div className="hide-lg-and-bigger">
        <PackageListCardSection titleId="book.newShipment.packages.packageQualificationTime">
          <PkgQualTimeSelect />
        </PackageListCardSection>
        {packoutDateTime && (
          <PackageListCardSection
            noTopLines
            className="merge-with-prev title-space-xxs"
            title={
              <RowLvl2>
                <Col span={12}>
                  <FormattedMessage id="book.newShipment.packages.packoutDate" />
                  <RequiredLabel required={packoutRequired} />
                </Col>
                <Col span={12}>
                  <FormattedMessage id="book.newShipment.packages.packoutTime" />
                  <RequiredLabel required={packoutRequired} />
                </Col>
              </RowLvl2>
            }
          >
            <PackoutDTPicker
              name="packoutDateTime"
              schemaName="packages.packoutDateTime"
            />
          </PackageListCardSection>
        )}
      </div>
    </>
  );
}

function CoolantAddonSubsection() {
  return (
    <div className="spaces-vert-norm">
      <RowLvl2>
        <Col span={12}>
          <FormItemSchemaDefinedSelect
            name={['coolantAddon', 'source']}
            labelId="book.newShipment.label.coolantSource"
            schemaName="packages.coolantAddon.source"
          />
        </Col>
        <Col span={12}>
          <FormItemSchemaDefinedSelect
            name={['coolantAddon', 'type']}
            labelId="book.newShipment.label.coolantType"
            schemaName="packages.coolantAddon.type"
          />
        </Col>
      </RowLvl2>
      <RowLvl2>
        <Col span={12}>
          <FormItemInputWeight
            unit={WeightUnit.KILOGRAM}
            name={['coolantAddon', 'amount']}
            labelId="book.newShipment.label.coolantAmount"
            schemaName="packages.coolantAddon.amount"
          />
        </Col>
        <Col span={12}>
          <FormItemInputNumericDuration
            name={['coolantAddon', 'replenishTime']}
            labelId="book.newShipment.label.replenishTime"
            schemaName="packages.coolantAddon.replenishTime"
          />
        </Col>
      </RowLvl2>
    </div>
  );
}

function AddonsSection() {
  const { isSm, isLg } = usePackageListResponsiveQueries();
  const { hasTemperature } = useShipmentMode();

  return (
    <>
      {/* Plain hiding is not enough here, because there are mutating elements inside and that would cause duplicate calls */}
      {isLg && (
        <RowLvl1>
          <Col span={12}>
            <PackageListCardSubtitle textId="book.newShipment.packages.deviceAddons.edit" />
            <PackageListDeviceAddonsField />
          </Col>
          {hasTemperature && (
            <>
              <DividerCol smallVerticalSpace />
              <Col span={12}>
                <PackageListCardSubtitle textId="book.newShipment.packages.coolantAddon" />
                <CoolantAddonSubsection />
              </Col>
            </>
          )}
        </RowLvl1>
      )}
      {isSm && (
        <div>
          {hasTemperature && (
            <PackageListCardSection titleId="book.newShipment.packages.coolantAddon">
              <CoolantAddonSubsection />
            </PackageListCardSection>
          )}
          <PackageListCardSection titleId="book.newShipment.packages.deviceAddons.edit">
            <PackageListDeviceAddonsField />
          </PackageListCardSection>
        </div>
      )}
    </>
  );
}

function TemperatureAndSensorsSection() {
  const { isSm, isLg } = usePackageListResponsiveQueries();
  const { hasTemperature } = useShipmentMode();

  return (
    <>
      {/* Plain hiding is not enough here, because there are mutating elements inside and that would cause duplicate calls */}
      {isLg && (
        <div>
          <PackageListCardSection titleId="book.newShipment.packages.temperatureAndSensors">
            {hasTemperature && (
              <>
                <TimingSubsection />
                <TwoLines className="size-10" />
              </>
            )}
            <AddonsSection />
          </PackageListCardSection>
        </div>
      )}
      {isSm && (
        <div>
          {hasTemperature && <TimingSubsection />}
          <AddonsSection />
        </div>
      )}
    </>
  );
}

function PrevTabsInfo({ className, type }) {
  const { commodity } = useServiceInformationDeps();

  const inner = (
    <div className={classNames('NewShipmentPackage-PrevTabsInfo', className)}>
      <div>
        <LabelWithValue
          size="lg"
          labelSize="full"
          labelId="book.newShipment.label.commodity"
          text={commodity}
        />
      </div>
    </div>
  );

  return type === 'header' ? (
    inner
  ) : (
    <>
      <TwoLinesSeparator className={className} />
      {inner}
      <TwoLinesSeparator className={className} />
    </>
  );
}

function ControlButtons({ index, saveChanges, stopEditing, remove }) {
  return (
    <div className="spaces-vert-sm">
      <ThreeLines />
      <div className="NewShipmentBottomControls">
        <Button
          size="small"
          onClick={() => {
            stopEditing();
            remove();
          }}
          data-subject="packages"
          data-action="delete"
        >
          <FormattedMessage id="buttons.delete" />
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={saveChanges}
          data-subject="packages"
          data-action="save"
        >
          <span className="hide-md-and-smaller">
            <FormattedMessage
              id="book.newShipment.packages.saveWithNumber"
              values={{ index: index + 1 }}
            />
          </span>
          <span className="hide-lg-and-bigger">
            <FormattedMessage id="book.newShipment.packages.save" />
          </span>
          <FAIcon icon="save" className="icon-21" />
        </Button>
      </div>
    </div>
  );
}

export default function PackageListPackageEditingMode({
  index,
  saveChanges,
  stopEditing,
  remove,
  pieceListProps,
  showTbd,
  tbdValue,
  onTbdChange,
}) {
  return (
    <div className="spaces-vert-md">
      <PackageCard index={index} className="editing">
        <PackageListCardHeader
          index={index}
          left={<PrevTabsInfo type="header" className="hide-md-and-smaller" />}
        />
        <PrevTabsInfo className="hide-lg-and-bigger" />
        <PackageDescriptionSection
          showTbd={showTbd}
          tbdValue={tbdValue}
          onTbdChange={onTbdChange}
        />
        <WeightSection index={index} />
        <TemperatureAndSensorsSection />
        <PackageListPieceListSection {...pieceListProps} />
      </PackageCard>
      <ControlButtons
        index={index}
        saveChanges={saveChanges}
        stopEditing={stopEditing}
        remove={remove}
      />
    </div>
  );
}
