import { groupBy, mapValues, property } from 'lodash-es';
import { Fragment, useContext } from 'react';
import { FormattedDateParts, FormattedTime } from 'react-intl';

import { DateFormat } from '../app/enums/DateFormat';
import { SettingsContext } from '../components/domainSpecific/settingsElements';
import { joinReactElements } from './reactUtils';

const Empty = ({ defaultValue }) => defaultValue || '-';

const DashIfNotValid = ({ value, defaultValue, children }) => {
  if (!value || !value.isValid()) {
    return <Empty defaultValue={defaultValue} />;
  }
  return children;
};

function prepareDateParameters(parts, dateFormat) {
  const partsByType = groupBy(parts, 'type');
  const vals = mapValues(partsByType, array =>
    array.length === 1 ? array[0].value : array.map(property('value'))
  );

  let dynamicParts = [];
  switch (dateFormat) {
    case DateFormat.DDMMYYYY:
      dynamicParts = [vals.day, vals.month, vals.year];
      break;
    case DateFormat.MMDDYYYY:
    default:
      dynamicParts = [vals.month, vals.day, vals.year];
  }

  return {
    dateFormat,
    dynamicParts,
    ...vals,
  };
}
function withCustomPartsFormatter({ formatter, ...formatProps }) {
  return Component =>
    function WithCustomPartsFormatter({ value, defaultValue, ...rest }) {
      const { dateFormat } = useContext(SettingsContext);
      return (
        <DashIfNotValid value={value} defaultValue={defaultValue}>
          <Component value={value} {...rest} {...formatProps}>
            {parts => formatter(prepareDateParameters(parts, dateFormat))}
          </Component>
        </DashIfNotValid>
      );
    };
}

function withMoreParts(components) {
  const WithMoreDateTimeParts = ({ value, separator, ...rest }) =>
    joinReactElements(
      components.map((C, i) => (
        // There will be a constant number of components, so it's no issue
        // eslint-disable-next-line react/no-array-index-key
        <C key={`part-${i}`} value={value} {...rest} />
      )),
      i => <Fragment key={`sep-${i}`}>{separator || ' '}</Fragment>
    );
  // Empty value needs to be handled in the joined component, so that it didn't produce "- -", but "-"
  return ({ value, defaultValue, ...rest }) => (
    <DashIfNotValid value={value} defaultValue={defaultValue}>
      <WithMoreDateTimeParts {...rest} value={value} />
    </DashIfNotValid>
  );
}

export function useDateFormats() {
  const { dateFormat } = useContext(SettingsContext);
  switch (dateFormat) {
    case DateFormat.DDMMYYYY:
      return {
        numeric: 'DD/MM/YYYY',
      };
    case DateFormat.MMDDYYYY:
    default:
      return {
        numeric: 'MM/DD/YYYY',
      };
  }
}

export const DateFormatShort = withCustomPartsFormatter({
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  formatter: ({ year, month, day }) => `${day}${month}${year}`.toUpperCase(),
})(FormattedDateParts);
export const DateFormatShortWithDashes = withCustomPartsFormatter({
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  formatter: ({ year, dynamicParts }) =>
    `${dynamicParts[0]}-${dynamicParts[1]}-${year}`.toUpperCase(),
})(FormattedDateParts);
export const DateFormatMedium = withCustomPartsFormatter({
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  formatter: ({ year, dynamicParts }) =>
    `${dynamicParts[0]} ${dynamicParts[1]}, ${year}`.toUpperCase(),
})(FormattedDateParts);
export const DateFormatShortWeekday = withCustomPartsFormatter({
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  weekday: 'short',
  formatter: ({ year, dynamicParts, weekday }) =>
    `${weekday} ${dynamicParts[0]}/${dynamicParts[1]}/${year}`.toUpperCase(),
})(FormattedDateParts);
export const DateFormatLong = withCustomPartsFormatter({
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
  formatter: ({ year, dynamicParts, weekday }) =>
    `${weekday}, ${dynamicParts[0]} ${dynamicParts[1]}, ${year}`.toUpperCase(),
})(FormattedDateParts);
export const DateFormatLongNoWeekday = withCustomPartsFormatter({
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  formatter: ({ year, dynamicParts }) =>
    `${dynamicParts[0]} ${dynamicParts[1]}, ${year}`.toUpperCase(),
})(FormattedDateParts);
export const DateFormatNumeric = withCustomPartsFormatter({
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  formatter: ({ year, dynamicParts }) =>
    `${dynamicParts[0]}/${dynamicParts[1]}/${year}`,
})(FormattedDateParts);
export const DateFormatNumericNoYear = withCustomPartsFormatter({
  year: undefined,
  month: '2-digit',
  day: '2-digit',
  formatter: ({ dynamicParts }) => `${dynamicParts[0]}/${dynamicParts[1]}`,
})(FormattedDateParts);

export const timeFormatShort = 'HH:mm';
export const TimeFormatShort = ({ value, ...rest }) => (
  <DashIfNotValid value={value}>
    <FormattedTime
      {...rest}
      value={value}
      hour="2-digit"
      minute="2-digit"
      hourCycle="h23"
    />
  </DashIfNotValid>
);

export const DateTimeFormatNumeric = withMoreParts([
  DateFormatNumeric,
  TimeFormatShort,
]);
export const DateTimeFormatMedium = withMoreParts([
  DateFormatMedium,
  TimeFormatShort,
]);
export const DateTimeFormatNumericNoYear = withMoreParts([
  DateFormatNumericNoYear,
  TimeFormatShort,
]);
export const DateTimeFormatShortWithDashes = withMoreParts([
  DateFormatShortWithDashes,
  TimeFormatShort,
]);
export const DateTimeFormatShort = withMoreParts([
  DateFormatShortWeekday,
  TimeFormatShort,
]);
