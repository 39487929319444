const { FormattedMessage } = require('react-intl');

export default function NoticeSection() {
  return (
    <div className="notice">
      <span className="text-uppercase" style={{ fontWeight: 700 }}>
        <FormattedMessage id="book.newQuote.notice" />
      </span>
      <br />
      <span style={{ fontWeight: 700 }}>
        <FormattedMessage id="book.newQuote.quotesValid" />
      </span>
      <br />
      <FormattedMessage id="book.newQuote.quotesAreBased" />
      <br />
      <span style={{ fontWeight: 700 }}>
        <FormattedMessage id="book.newQuote.customersShould" />
      </span>
      <br />
      <FormattedMessage id="book.newQuote.notAllPotential" />
    </div>
  );
}
