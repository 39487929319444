import { Col, Modal, Row } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { CustomLoaderButton } from '../components/buttons/buttons';
import { ConfigurableCard } from '../components/layout/cardElements';
import { QuotePackagesPreviewMode } from '../forms/shipments/packageList/QuotePackagesPreviewMode';
import {
  extractActualWeight,
  extractBilledWeight,
  getPackageCount,
  getPackageTotal,
} from '../forms/shipments/shipmentCommon';
import { WeightFormat } from '../components/domainSpecific/measuringUnitsElements';
import { CurrencyFormat } from '../components/domainSpecific/currencyElements';
import { YesNoFormat } from '../components/forms/checkable';
import { DateFormatShortWithDashes } from '../utils/dateFormatting';

export default function ViewQuoteBreakdownDialog({ isOpen, close }) {
  const mockQuote = {
    accountNumber: 'SC22516',
    serviceType: {
      code: 'BEST',
      text: 'Best Available',
    },
    origin: {
      companyName: '1 Way',
      address: {
        country: 'United States',
        addressLines: ['1550 Main St'],
        postalCode: '11421',
        city: 'Woodhaven',
        stateProvince: 'New York',
      },
    },
    destination: {
      companyName: 'Abc Company - New',
      address: {
        country: 'United States',
        addressLines: ['345 Elm Street', 'Suite 35'],
        postalCode: '11372',
        city: 'San Francisco',
        stateProvince: 'California',
      },
    },
    packages: [
      {
        actualWeight: 5,
        billedWeight: 0.5,
        dimensionalWeight: 0.5,
        height: 5,
        length: 5,
        width: 5,
      },
    ],
    expiryDate: '2025-02-07T12:09:54',
    createDate: '2025-01-29T12:09:54',
    quoteTotal: 783,
    quoteNo: '208490045W',
  };

  const summaryPieces = [
    {
      labelId: 'book.newShipment.quantityWeightSummary.count',
      value: getPackageCount(mockQuote?.packages),
    },
    {
      labelId: 'book.newShipment.quantityWeightSummary.actualWeight',
      value: getPackageTotal(mockQuote?.packages, extractActualWeight),
      isWeightFormat: true,
    },
    {
      labelId: 'book.newShipment.quantityWeightSummary.billedWeight',
      value: getPackageTotal(mockQuote?.packages, extractBilledWeight),
      isWeightFormat: true,
    },
  ];

  const addresses = [
    {
      labelId: 'book.quote.quoteBreakdown.pickupAddress',
      address: mockQuote?.origin?.address,
    },
    {
      labelId: 'book.quote.quoteBreakdown.deliveryAddress',
      address: mockQuote?.destination?.address,
    },
  ];

  const notIncludedItems = [
    'weightDiscrepancies',
    'attemptedDeliveries',
    'attemptedCollections',
    'waitingTime',
    'vehicleInUse',
    'reIcing',
    'dutiesAndTaxes',
  ];

  const itemization = [
    {
      type: 'base',
      value: 495.85,
    },
    {
      type: 'weight',
      value: 70,
    },
    {
      type: 'deliveryMileage',
      value: 61,
    },
    {
      type: 'clearance',
      value: 108,
    },
    {
      type: 'fuelSurcharge',
      value: 38.1,
    },
    {
      type: 'securitySurcharge',
      value: 92.95,
    },
    {
      type: 'nightWEHoliday',
      value: 103,
    },
    {
      type: 'miscellaneous',
      value: null,
    },
    {
      type: 'additionalAWB',
      value: null,
    },
    {
      type: 'commuterSurcharge',
      value: null,
    },
    {
      type: 'holdOvernight',
      value: null,
    },
    {
      type: 'switchTransfer',
      value: null,
    },
    {
      type: 'dangerousGood',
      value: null,
    },
  ];

  const quoteInfo1 = [
    {
      labelId: 'quoteDate',
      value: (
        <DateFormatShortWithDashes value={moment(mockQuote?.createDate)} />
      ),
    },
    {
      labelId: 'clientName',
      value: mockQuote?.origin?.companyName,
    },
    {
      labelId: 'ACCT',
      value: mockQuote?.accountNumber,
    },
    {
      labelId: 'serviceType',
      value: mockQuote?.serviceType?.code,
    },
    {
      labelId: 'quotedAmount',
      value: (
        <CurrencyFormat
          value={mockQuote?.quoteTotal}
          minimumFractionDigits={2}
        />
      ),
    },
  ];

  const quoteInfo2 = [
    {
      labelId: 'validToDate',
      value: (
        <DateFormatShortWithDashes value={moment(mockQuote?.expiryDate)} />
      ),
    },
    {
      labelId: 'dateValidFor',
    },
    {
      labelId: 'jobQuote',
      value: mockQuote?.quoteNo,
    },
    {
      labelId: 'tariffID',
      value: 'T4',
    },
  ];

  return (
    <Modal
      className="ViewQuoteBreakdownDialog"
      open={isOpen}
      footer={null}
      closable={false}
      width={600}
      centered
    >
      <div className="ViewQuoteBreakdownDialog-ControlButtons">
        <h1>
          <FormattedMessage id="book.quote.quoteBreakdown.title" />
        </h1>
        <CustomLoaderButton
          type="primary"
          onClick={async () => {
            close();
          }}
          data-action="close"
        >
          <FAIcon icon="times-circle" />
          <span>
            <FormattedMessage id="buttons.close" />
          </span>
        </CustomLoaderButton>
      </div>
      <div className="ViewQuoteBreakdownDialog-ScrollablePanel">
        <Row gutter={[20, 10]} className="ViewQuoteBreakdownDialog-QuoteInfo">
          <Col span={12}>
            {quoteInfo1?.map(({ labelId, value }) => (
              <div>
                <span>
                  <FormattedMessage
                    id={`book.quote.quoteBreakdown.${labelId}`}
                  />
                  :
                </span>
                <span>{value}</span>
              </div>
            ))}
          </Col>
          <Col span={12}>
            {quoteInfo2?.map(({ labelId, value }) => (
              <div>
                <span>
                  <FormattedMessage
                    id={`book.quote.quoteBreakdown.${labelId}`}
                  />
                  {value && ':'}
                </span>
                {value && <span>{value}</span>}
              </div>
            ))}
          </Col>
        </Row>
        <Row
          gutter={[20, 10]}
          className="ViewQuoteBreakdownDialog-ItemizationSection"
        >
          <Col span={12}>
            <h2>
              <FormattedMessage id="book.quote.quoteBreakdown.quoteItemization" />
            </h2>
            {itemization?.map(({ type, value }) => (
              <div>
                <span>
                  <FormattedMessage id={`book.quote.quoteBreakdown.${type}`} />
                </span>
                <span>
                  <CurrencyFormat value={value} minimumFractionDigits={2} />
                </span>
              </div>
            ))}
          </Col>
          <Col span={12}>
            <h2>
              <FormattedMessage id="book.quote.quoteBreakdown.notIncluded" />
            </h2>
            {notIncludedItems?.map(item => (
              <p>
                <FormattedMessage id={`book.quote.quoteBreakdown.${item}`} />
              </p>
            ))}
          </Col>
        </Row>
        <div className="ViewQuoteBreakdownDialog-NoticeSection">
          <p>
            <FormattedMessage id="book.quote.quoteBreakdown.notice" />
          </p>
          <span>
            <FormattedMessage id="book.quote.quoteBreakdown.noticeMessage" />
          </span>
        </div>
        <Row
          gutter={[20, 10]}
          className="ViewQuoteBreakdownDialog-AddressSection"
        >
          {addresses?.map(({ labelId, address }) => {
            const { country, addressLines, postalCode, city, stateProvince } =
              address || {};
            return (
              <Col span={12}>
                <ConfigurableCard titleId={labelId}>
                  {[
                    country,
                    ...addressLines,
                    `${city}, ${stateProvince} ${postalCode}`,
                  ].map(text => (
                    <div>{text}</div>
                  ))}
                </ConfigurableCard>
              </Col>
            );
          })}
        </Row>
        <ConfigurableCard
          className="ViewQuoteBreakdownDialog-PiecesSection"
          titleId="book.quote.quoteBreakdown.piecesWeightSummary"
        >
          <Row className="ViewQuoteBreakdownDialog-PiecesSection__Preview">
            {summaryPieces?.map(({ labelId, value, isWeightFormat }) => (
              <Col span={8}>
                <span>
                  <FormattedMessage id={labelId} />:
                </span>
                <span>
                  {isWeightFormat ? <WeightFormat value={value} /> : value}
                </span>
              </Col>
            ))}
          </Row>
          {mockQuote?.packages?.map((piece, index) => (
            <QuotePackagesPreviewMode index={index} value={piece} viewMode />
          ))}
        </ConfigurableCard>
      </div>
    </Modal>
  );
}
