import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import SectionHeader from '../SectionHeader';
import Badge from './Badge';
import DateTimeDisplay from '../DateTimeDisplay';
import {
  DateFormatNumeric,
  DateTimeFormatShort,
} from '../../../../utils/dateFormatting';
import { TwoLinesSeparator } from '../../../../components/layout/layoutElements';
import AddressDisplay from './AddressDisplay';
import WaypointLine from './WaypointLine';

export default function FlightInformationSection({
  sectionTitle,
  dateTime,
  highlightedText,
  address,
  badgeLabel,
  flight,
  distance,
}) {
  return (
    <div className="flight-info-section">
      <SectionHeader title={sectionTitle} />
      <div className="badge-date-section">
        <Badge label={badgeLabel} />
        <Typography.Title level={4}>
          <DateTimeDisplay
            dateTime={dateTime}
            formatComponent={DateTimeFormatShort}
          />
        </Typography.Title>
      </div>
      <TwoLinesSeparator />
      <p className="text-highlight">{highlightedText}</p>
      {address && (
        <>
          <TwoLinesSeparator />
          <AddressDisplay address={address} />
        </>
      )}
      {flight &&
        [
          { label: 'book.newQuote.lockout', value: flight.departure.scheduled },
          { label: 'book.newQuote.departs', value: flight.departure.estimated },
        ].map(({ label, value }) => (
          <>
            <TwoLinesSeparator />
            <div style={{ display: 'flex' }}>
              <FormattedMessage id={label} />:
              <DateTimeDisplay
                dateTime={value}
                formatComponent={DateFormatNumeric}
              />
            </div>
          </>
        ))}
      <TwoLinesSeparator />
      {distance && <WaypointLine />}
    </div>
  );
}
