import { useQuery } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import {
  groupBy,
  includes,
  isObject,
  keys,
  noop,
  range,
  some,
  sortBy,
  stubTrue,
  toPairs,
} from 'lodash-es';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import {
  formToInputAddressBookEntry,
  graphQLToFormAddressBookEntry,
} from '../../app/data/addressBookConversions';
import {
  useCreateAddressBookEntry,
  useEditAddressBookEntry,
  useRemoveAddressBookEntry,
} from '../../app/graphql/addressBookActions';
import {
  ADDRESS_BOOK_ENTRIES_QUERY,
  ADDRESS_BOOK_ENTRY_FORM_AUTOFILL_QUERY,
  ADDRESS_BOOK_ENTRY_FORM_GET_OPTIONS_QUERY,
  ADDRESS_BOOK_ENTRY_FORM_SCHEMA_QUERY,
  VALIDATE_ADDRESS_BOOK_ENTRY_FORM_FIELD_QUERY,
} from '../../app/graphql/addressBookQueries';
import { useDialogControls } from '../../common/utils/dialogUtils';
import { formatCodeValue } from '../../common/utils/formatUtils';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import { normalizeString } from '../../common/utils/stringUtils';
import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../components/buttons/IconButton';
import { renderDataStateIndicator } from '../../components/data/dataStateHandlers';
import { DialogsParentContext } from '../../components/dialogs/DialogsParentContext';
import { SimpleConfirmDialog } from '../../components/dialogs/SimpleConfirmDialog';
import { DRAWER_MEDIA_MAPPER } from '../../components/drawers/drawerCommon';
import { FormItemInputText } from '../../components/forms/basicFormElements';
import {
  DynamicFormDependenciesProvider,
  useDynamicOriginalValueDependency,
} from '../../components/forms/dynamic/dynamicFormDependencies';
import { DynamicFormSchemaProvider } from '../../components/forms/dynamic/dynamicFormSchema';
import { useFormContext } from '../../components/forms/forms';
import TemplatesFormDrawer from '../../components/popups/templates/TemplatesFormDrawer';
import { useTemplatesActiveItem } from '../../components/popups/templates/templatePopupUtils';
import { ResponsiveOverrideSubtree } from '../../components/responsive/ResponsiveOverrideSubtree';
import { BlockLink } from '../../components/typography';
import { SavedShipmentProvider } from '../../forms/shipments/newShipment/SavedShipmentProvider';
import { JobLocationCards } from '../../forms/shipments/newShipment/newShipmentElements';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { AddressBookHeaderRightContent } from './AddressBookHeader';
import { AddressBookList } from './AddressBookList';

const NON_ALPHA = '#';
const ALPHABET = [
  NON_ALPHA,
  ...range('a'.charCodeAt(), 'z'.charCodeAt() + 1).map(charCode =>
    String.fromCharCode(charCode)
  ),
];

function AddressBookDrawerListLetterShortcuts({
  letters,
  isFormVisible,
  scrollToLetter,
}) {
  const [open, setOpen] = useState(true);

  return (
    <div
      className={classNames('AddressBookList-LetterShortcuts', {
        open: open && !isFormVisible,
        closed: isFormVisible,
      })}
    >
      <IconButton
        icon="arrows-alt-h"
        className="hide-lg-and-bigger"
        onClick={() => setOpen(!open)}
      />
      {ALPHABET.map(letter => {
        const present = !letters || includes(letters, letter);
        return (
          <BlockLink
            key={letter}
            className={classNames({ disabled: !present })}
            onClick={present ? () => scrollToLetter(letter) : noop}
          >
            {letter}
          </BlockLink>
        );
      })}
    </div>
  );
}

function makeAddressBookSections(entries) {
  if (!entries) {
    return [];
  }
  const sortedEntries = sortBy(entries, 'companyName');
  const entriesByLetter = groupBy(sortedEntries, entry => {
    const firstLetter = normalizeString(entry.companyName).slice(0, 1);
    return /[a-z]/.test(firstLetter) ? firstLetter : NON_ALPHA;
  });
  const sections = sortBy(toPairs(entriesByLetter), ([letter]) => letter);
  const sectionsWithIndices = sections.map(([letter, subentries]) => [
    letter,
    subentries.map((entry, indexInSection) => ({
      data: entry,
      index: indexInSection,
    })),
  ]);
  return { sections: sectionsWithIndices, letters: keys(entriesByLetter) };
}

function AddressBookNoDataDialog({ onAdd, isOpen, onCancel, readOnly }) {
  return (
    <SimpleConfirmDialog
      textId="addressBook.warning.noData"
      className="AddressBookNoDataDialog size-sm"
      visible={isOpen}
    >
      <Row
        align="top"
        justify={readOnly ? 'center' : 'start'}
        gutter={pxToNumber(cssVariables.spaceNorm)}
      >
        <Col>
          <Button onClick={onCancel} ghost>
            <FormattedMessage id="buttons.cancel" />
          </Button>
        </Col>
        {!readOnly && (
          <Col className="Flex1">
            <Button className="btn-blue" onClick={onAdd}>
              <FAIcon icon="plus" />
              <span>
                <FormattedMessage id="addressBook.buttons.addContact" />
              </span>
            </Button>
          </Col>
        )}
      </Row>
    </SimpleConfirmDialog>
  );
}

function filterAddressEntries(entries, searchValue) {
  return entries.filter(entry => {
    if (!searchValue) {
      return true;
    }
    const searchedFields = [
      entry.companyName,
      entry.address.addressLine1,
      entry.address.postalCode,
      formatCodeValue(entry.address.city),
      formatCodeValue(entry.address.stateProvince),
      formatCodeValue(entry.address.country),
      entry.keyword,
    ];
    return some(
      searchedFields,
      fieldValue =>
        normalizeString(fieldValue).indexOf(normalizeString(searchValue)) > -1
    );
  });
}

function AddressBookDrawerListContent({
  onEdit,
  onApply,
  active,
  onDelete,
  removeDialogClassName,
  removeText,
  removeTextId,
  removeSuccessMessageId,
  isFormVisible,
  openNoDataDialog,
  searchValue,
  readOnly,
  countryCodeRestrict,
  namePrefix,
}) {
  const listRef = useRef();
  const accountNumber = useDynamicOriginalValueDependency('accountNumber');
  const { data, loading, error } = useQuery(ADDRESS_BOOK_ENTRIES_QUERY, {
    variables: { accountNumber, countryCode: countryCodeRestrict },
  });
  const indicator = renderDataStateIndicator({ data, loading, error });
  const allEntries = data && extractGraphqlEntity(data).data;
  const entries = allEntries && filterAddressEntries(allEntries, searchValue);
  const { sections, letters } = useMemo(
    () => makeAddressBookSections(entries),
    [entries]
  );

  const allEntriesLength = allEntries?.length || 0;
  useEffect(() => {
    // Open no data dialog anytime loading has been finished, there is no data
    // and this mode is visible
    if (!loading && allEntriesLength === 0 && !isFormVisible) {
      openNoDataDialog();
    }
  }, [allEntriesLength, isFormVisible, loading, openNoDataDialog]);

  return (
    indicator || (
      <>
        <AddressBookDrawerListLetterShortcuts
          letters={letters}
          isFormVisible={isFormVisible}
          scrollToLetter={letter => listRef.current.scrollToLetter(letter)}
        />
        <AddressBookList
          sections={sections}
          active={active}
          onEdit={onEdit}
          onApply={onApply}
          onDelete={onDelete}
          removeDialogClassName={removeDialogClassName}
          removeText={removeText}
          removeTextId={removeTextId}
          removeSuccessMessageId={removeSuccessMessageId}
          ref={listRef}
          readOnly={readOnly}
          namePrefix={namePrefix}
        />
      </>
    )
  );
}

function AddressBookFormContent() {
  const { values } = useFormContext();
  const accountNumber = useDynamicOriginalValueDependency('accountNumber');

  return (
    <ResponsiveOverrideSubtree mapper={DRAWER_MEDIA_MAPPER}>
      <DynamicFormSchemaProvider
        query={ADDRESS_BOOK_ENTRY_FORM_SCHEMA_QUERY}
        optionsQuery={ADDRESS_BOOK_ENTRY_FORM_GET_OPTIONS_QUERY}
        fieldValidationQuery={VALIDATE_ADDRESS_BOOK_ENTRY_FORM_FIELD_QUERY}
        autofillQuery={ADDRESS_BOOK_ENTRY_FORM_AUTOFILL_QUERY}
        variables={{ accountNumber }}
        skip={!accountNumber}
        queryName="addressBookFieldValidation"
        clearCache
      >
        <DynamicFormDependenciesProvider values={values}>
          <div className="spaces-vert-norm2">
            <FormItemInputText
              name="keyword"
              schemaName="keyword"
              labelId="addressBook.keyword"
            />
            <JobLocationCards
              firstTitleId="addressBook.countrySection.label"
              secondTitleId="addressBook.shipmentContactInformation"
              enableAddressLookup={{ webResults: true }}
            />
          </div>
        </DynamicFormDependenciesProvider>
      </DynamicFormSchemaProvider>
    </ResponsiveOverrideSubtree>
  );
}

export default function AddressBookDrawer({
  visible,
  data,
  open,
  setData,
  onClose,
  readOnly,
  countryCodeRestrict,
  namePrefix,
}) {
  const { values } = useFormContext();
  const accountNumber = useDynamicOriginalValueDependency('accountNumber');
  const { activeItem, setActiveItem, afterSave } = useTemplatesActiveItem({
    mapItemToForm: graphQLToFormAddressBookEntry,
    isEqual: stubTrue,
  });
  const onCancel = useCallback(() => setData(), [setData]);

  const createAddressBookEntry = useCreateAddressBookEntry({ accountNumber });
  const editAddressBookEntry = useEditAddressBookEntry({ accountNumber });
  const removeAddressBookEntry = useRemoveAddressBookEntry({ accountNumber });

  const [searchValue, searchOnChange] = useState();

  const getCreateInitialValues = () =>
    isObject(data?.adding)
      ? graphQLToFormAddressBookEntry(data.adding)
      : values;
  const getEditInitialValues = entry => graphQLToFormAddressBookEntry(entry);

  const { setDialogOpen } = useContext(DialogsParentContext);
  const noDataDialog = useDialogControls({ onOpenChange: setDialogOpen });
  const openNoDataDialog =
    noDataDialog.isOpen || !visible
      ? noop
      : () => {
          noDataDialog.open();
          onClose();
        };

  const REMOVE_PROPS = {
    onDelete: async id => {
      await removeAddressBookEntry(id);
      onCancel();
    },
    removeTextId: 'addressBook.delete.dialog.text',
    removeSuccessMessageId: 'addressBook.delete.success',
  };

  const isFormVisible = data?.editing || data?.adding;

  return (
    <SavedShipmentProvider enabled={false}>
      <TemplatesFormDrawer
        destroyOnClose={false}
        visible={visible}
        data={data}
        readOnly={readOnly}
        setData={setData}
        onClose={onClose}
        onCancel={onCancel}
        titleId="addressBook.title"
        headerRightContent={
          <div
            className={classNames('AddressBook__HeaderRightContent', {
              disabled: isFormVisible,
            })}
          >
            <AddressBookHeaderRightContent
              searchValue={searchValue}
              searchOnChange={searchOnChange}
              setData={setData}
              readOnly={readOnly}
            />
          </div>
        }
        getCreateInitialValues={getCreateInitialValues}
        getEditInitialValues={getEditInitialValues}
        onSave={async (id, formValues) => {
          const input = formToInputAddressBookEntry(formValues, accountNumber);
          const response = id
            ? await editAddressBookEntry(id, input)
            : await createAddressBookEntry(input);
          onCancel();
          afterSave({ ...input, id: response.id });
        }}
        formClassName="AddressBookForm"
        formName="addressBookEntry"
        formComponent={AddressBookFormContent}
        createTitleId="addressBook.add.title"
        createSuccessMessageId="addressBook.create.success"
        editTitleId="addressBook.edit.title"
        editSuccessMessageId="addressBook.edit.success"
        {...REMOVE_PROPS}
      >
        <AddressBookDrawerListContent
          onEdit={val => setData({ editing: val })}
          onApply={({ id }) => {
            onClose();
            setActiveItem({ id });
          }}
          onClose={onClose}
          active={activeItem}
          isFormVisible={isFormVisible}
          openNoDataDialog={openNoDataDialog}
          searchValue={searchValue}
          readOnly={readOnly}
          countryCodeRestrict={countryCodeRestrict}
          namePrefix={namePrefix}
          {...REMOVE_PROPS}
        />
      </TemplatesFormDrawer>
      <AddressBookNoDataDialog
        onAdd={() => {
          open({ adding: true });
          noDataDialog.close();
        }}
        isOpen={noDataDialog.isOpen}
        onCancel={noDataDialog.close}
        readOnly={readOnly}
      />
    </SavedShipmentProvider>
  );
}
