import routes from '../app/routes';
import { newShipmentGuard } from '../components/guards/pageGuards';
import TopMenu from '../components/menus/TopMenu';

const TOP_MENU_ITEMS = [
  { to: routes.monitor, labelId: 'menu.monitor' },
  { to: routes.quoting.newQuote, labelId: 'menu.quote' },
  {
    to: routes.book.newShipment,
    labelId: 'menu.book',
    guard: newShipmentGuard,
  },
];

export const MainTopMenu = props => (
  <TopMenu {...props} definitions={TOP_MENU_ITEMS} />
);
