import { Form } from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { SettingsContext } from '../../../components/domainSpecific/settingsElements';

function mapFormValuesToDependencies(formValues) {
  return {
    ...formValues,
    // Synthetic values used for dependencies only
    accountNumber: formValues.account?.number,
    pickupDateTime: formValues.origin?.pickupDateTime,
  };
}

export default function useNewQuoteFormState() {
  const [account, setAccount] = useState();
  const [values, setValues] = useState({});
  const { unitSystem: parentUnitSystem, loading: loadingUnitSystem } =
    useContext(SettingsContext);
  const [unitSystemOverride, setUnitSystemOverride] = useState();

  // We don't want to allow changing unit system in the middle of creating shipment,
  // therefore we capture the initial value
  const [initialParentUnitSystem, setInitialParentUnitSystem] = useState(null);
  useEffect(() => {
    if (!loadingUnitSystem && !initialParentUnitSystem) {
      setInitialParentUnitSystem(parentUnitSystem);
    }
  }, [initialParentUnitSystem, loadingUnitSystem, parentUnitSystem]);

  // Refresh may be called immediately after a sequence of setters and we want it
  // to work with the newest possible values
  const currentState = useRef({ account, values, unitSystemOverride });

  // Keep in sync with steps - hooks cannot be called inside loops
  const [form] = Form.useForm();

  // Extracts the current form values, contains all the data required for submit
  const extractValues = useCallback(
    overrides =>
      mapFormValuesToDependencies({
        account: currentState.current.account,
        ...form.getFieldsValue(),
        unitSystem:
          currentState.current.unitSystemOverride || initialParentUnitSystem,
        ...overrides,
      }),
    [form, initialParentUnitSystem]
  );

  const refreshValues = useCallback(
    overrides => {
      const newValues = {
        ...currentState.current.values,
        ...extractValues(overrides),
      };
      setValues(newValues);
      currentState.current.values = newValues;
      return newValues;
    },
    [extractValues]
  );

  return {
    form,
    refreshValues,
    account,
    setAccount: acc => {
      setAccount(acc);
      currentState.current.account = acc;
      refreshValues();
    },
    unitSystem: unitSystemOverride || initialParentUnitSystem,
    setUnitSystem: us => {
      setUnitSystemOverride(us);
      currentState.current.unitSystemOverride = us;
      refreshValues();
    },
    values,
    extractValues,
  };
}
