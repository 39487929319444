import { Card, Col, Row, Typography } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { generatePath, useHistory } from 'react-router';

import { AsyncCallbackButton } from '../../../components/data/asyncActionElements';
import { DateFormatShort } from '../../../utils/dateFormatting';
import { TwoLinesSeparator } from '../../../components/layout/layoutElements';
import { InlineLink } from '../../../components/typography';
import ViewQuoteBreakdownDialog from '../../../dialogs/ViewQuoteBreakdownDialog';
import routes from '../../../app/routes';

export function QuoteButtons({ onSubmit, quoteNumber }) {
  const { push } = useHistory();
  const dateFiveDaysFromNow = moment().add(5, 'days');

  const onDismissClick = () => {
    push(generatePath(routes.monitor));
  };

  return (
    <div className="quote-buttons-container">
      {!quoteNumber && (
        <AsyncCallbackButton
          className="quote-button save-button"
          type="secondary"
          callback={onSubmit}
        >
          <FormattedMessage id="book.newQuote.save" />
          <div className="quote-expires text-uppercase">
            (
            <FormattedMessage id="book.newQuote.expires" />
            <span> </span>
            <DateFormatShort value={moment(dateFiveDaysFromNow)} />)
          </div>
        </AsyncCallbackButton>
      )}

      {quoteNumber && (
        <AsyncCallbackButton
          className="quote-button save-button"
          type="secondary"
          callback={onSubmit('review')}
        >
          <FormattedMessage id="book.newQuote.reviewQuote" />
        </AsyncCallbackButton>
      )}

      <AsyncCallbackButton
        className="quote-button continue-button"
        type="primary"
        callback={onSubmit}
      >
        <FormattedMessage id="book.newQuote.continue" />
      </AsyncCallbackButton>

      <AsyncCallbackButton
        className="quote-button cancel-button"
        type="default"
        onClick={onDismissClick}
      >
        <FormattedMessage id="buttons.dismiss" />
      </AsyncCallbackButton>
    </div>
  );
}

export function QuoteSummaryHeader() {
  const [isBreakdownDialogOpen, setBreakdownDialogOpen] = useState(false);
  const quoteNumber = '00163729278';

  const data = [
    { label: 'SERVICE', value: 'Next Flight Out' },
    { label: 'COMMODITY', value: 'General Cargo' },
    { label: 'DANGEROUS GOODS', value: 'No' },
    { label: 'PIECES', value: '2' },
    { label: 'BILL WEIGHT', value: '500 lbs' },
    { label: 'UN# 201.6', value: ' Flamable' },
  ];

  const firstRow = data.slice(0, 3);
  const secondRow = data.slice(3);
  console.log('QuoteSummaryHeader', quoteNumber);
  return (
    <div>
      <Card className="quote-summary-header">
        <Row className="header-row" align="middle" justify="space-between">
          <Col>
            <Typography.Title level={1}>
              <FormattedMessage id="book.newQuote.resultTitle" />
            </Typography.Title>
            {quoteNumber && (
              <div className="quote-id-label">
                <FormattedMessage id="book.quote.quoteId" />
                <span className="quote-id">{quoteNumber}</span>
              </div>
            )}
          </Col>
          <Col>
            <Row className="details-grid" gutter={[16, 16]}>
              {firstRow.map(item => (
                <Col key={item} className="details-grid-item">
                  <span className="label">{item.label}</span>
                  <span className="value">{item.value}</span>
                </Col>
              ))}
            </Row>
            <TwoLinesSeparator className="full-width-separator" />

            <Row className="details-grid" gutter={[16, 16]}>
              {secondRow.map((item, index) => {
                const isLastElement = index === secondRow.length - 1;
                return (
                  <Col key={item} className="details-grid-item">
                    <span className={isLastElement ? '' : 'label'}>
                      {item.label}
                    </span>
                    <span className={isLastElement ? '' : 'value'}>
                      {item.value}
                    </span>
                  </Col>
                );
              })}
            </Row>
            <TwoLinesSeparator className="full-width-separator" />
          </Col>
          <Col>
            <Typography.Title level={1} className="price">
              <FormattedMessage id="book.newQuote.yourPrice" /> | $783
            </Typography.Title>
            <InlineLink onClick={() => setBreakdownDialogOpen(true)}>
              <FormattedMessage id="book.quote.quoteBreakdown" />
            </InlineLink>
          </Col>
        </Row>
      </Card>

      <QuoteButtons
        onSubmit={buttonClicked => console.log(buttonClicked)}
        quoteNumber={quoteNumber}
      />

      <ViewQuoteBreakdownDialog
        isOpen={isBreakdownDialogOpen}
        close={() => setBreakdownDialogOpen(false)}
      />
    </div>
  );
}
