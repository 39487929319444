import { gql } from '@apollo/client';

import {
  formFieldFragment,
  makeLocationFieldsFragment,
  makeLocationFragment,
} from './commonFragments';

const addressEntryFragment = makeLocationFragment({
  fullState: true,
  otherFields: ['id', 'keyword'].join('\n'),
});

export const ADDRESS_BOOK_ENTRIES_QUERY = gql`
  query AddressBookEntriesQuery($accountNumber: String!, $countryCode: String) {
    addressBookEntries(accountNumber: $accountNumber, countryCode: $countryCode) {
      data ${addressEntryFragment}
      enableEditButton
    }
  }
`;

export const CREATE_ADDRESS_BOOK_ENTRY_MUTATION = gql`
  mutation CreateAddressBookEntryMutation($input: AddressBookEntryInput!) {
    createAddressBookEntry(input: $input) {
      id
    }
  }
`;

export const EDIT_ADDRESS_BOOK_ENTRY_MUTATION = gql`
  mutation EditAddressBookEntryMutation(
    $id: String!
    $input: AddressBookEntryInput!
  ) {
    editAddressBookEntry(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_ADDRESS_BOOK_ENTRY_MUTATION = gql`
  mutation DeleteAddressBookEntryMutation($id: String!) {
    deleteAddressBookEntry(id: $id)
  }
`;

const addressBookEntryFieldsFragment = makeLocationFieldsFragment({
  otherFields: `keyword ${formFieldFragment}`,
});
export const ADDRESS_BOOK_ENTRY_FORM_SCHEMA_QUERY = gql`
  query AddressBookEntryFormSchemaQuery($accountNumber: String!) {
    addressBookFormSchema(accountNumber: $accountNumber) ${addressBookEntryFieldsFragment}
  }
`;

export const ADDRESS_BOOK_ENTRY_FORM_GET_OPTIONS_QUERY = gql`
  query AddressBookEntryFormGetOptionsQuery($input: FieldOptionsInput!) {
    addressBookFieldOptions(input: $input) {
      values {
        code
        text
      }
    }
  }
`;

export const VALIDATE_ADDRESS_BOOK_ENTRY_FORM_FIELD_QUERY = gql`
  query ValidateAddressBookEntryFormFieldQuery(
    $input: AsyncFieldValidationInput!
  ) {
    addressBookFieldValidation(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const ADDRESS_BOOK_ENTRY_FORM_AUTOFILL_QUERY = gql`
  query AddressBookEntryFormAutofillQuery($input: AsyncFieldAutofillInput!) {
    addressBookFieldAutofill(input: $input) {
      value
    }
  }
`;
