import { mapValues, noop, omit } from 'lodash-es';
import { createContext, useState } from 'react';

export const LocationMode = {
  origin: 'origin',
  destination: 'destination',
};

const LocationModeInfo = {
  [LocationMode.origin]: {
    labelId: 'job.origin',
    getJobLocation: job => job.origin,
  },
  [LocationMode.destination]: {
    labelId: 'job.destination',
    getJobLocation: job => job.destination,
  },
};
export function getLocationModeInfo(locationMode) {
  return LocationModeInfo[locationMode] || LocationModeInfo.destination;
}

const EMPTY_CURSOR = {
  rows: [],
  hasNext: false,
  loadNext: noop,
  totalRows: 0,
  error: undefined,
  loading: false,
  loadingPage1: false,
};

export const MonitorContext = createContext({
  activeJob: undefined,
  setActiveJob: noop,
  locationMode: LocationMode.destination,
  setLocationMode: noop,
  variables: {},
  data: {
    active: EMPTY_CURSOR,
    saved: EMPTY_CURSOR,
    live: EMPTY_CURSOR,
    delivered: EMPTY_CURSOR,
    all: EMPTY_CURSOR,
  },
  activeSection: 'live',
  setActiveSection: noop,
});

export function MonitorProvider({ data, variables, children }) {
  const [activeJob, setActiveJob] = useState();
  const [activeSection, setActiveSection] = useState('live');
  const [locationMode, setLocationMode] = useState(LocationMode.destination);

  const value = {
    data: mapValues(data, obj => (obj ? omit(obj, ['refresh']) : obj)),
    variables,
    activeJob,
    setActiveJob,
    locationMode,
    setLocationMode,
    activeSection,
    setActiveSection,
  };

  return (
    <MonitorContext.Provider value={value}>{children}</MonitorContext.Provider>
  );
}
