import React from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { renderDataStateIndicator } from '../components/data/dataStateHandlers';
import { pxToNumber } from '../utils/cssUtils';
import { cssVariables } from '../styles/cssVariables';
import BaseLoggedPage from '../templates/BaseLoggedPage';
import { QuoteSummaryHeader } from './quote/summary/QuoteSummaryHeader';
import ShipmentDetailMap from './shipmentDetail/ShipmentDetailMap';
import { QuoteFlightItinerary } from './quote/summary/QuoteFlightItinerary';
import { QUOTES_QUERY } from '../app/graphql/quoteQueries';
import NoticeSection from './quote/summary/NoticeSection';
import quoteData from './quote/summary/data/quote-mock.json';
import shipment from './quote/summary/data/shipment-mock.json';
import ShipmentDetails from './quote/summary/ShipmentDetails';
import ModalOverlay from '../components/dialogs/ModalOverlay';
import ModalPage from '../templates/ModalPage';

const QuoteResultPage = () => {
  const { jobNumber } = useParams();
  const { data, loading, error } = useQuery(QUOTES_QUERY, {
    variables: { jobNumber },
    fetchPolicy: 'cache-and-network',
  });
  const indicator = renderDataStateIndicator({ data, loading, error });

  return (
    <ModalOverlay>
      <ModalPage
        id="QuoteResultPage"
        className="ReleaseShipmentPage height-extending-error"
        wrapInScrollbar
      >
        <QuoteSummaryHeader
          quoteNumber={jobNumber || data?.customerQuotes?.data[0]?.quoteNumber}
        />
        {indicator || (
          <Row
            gutter={[
              pxToNumber(cssVariables.spaceNorm2),
              pxToNumber(cssVariables.spaceNorm2),
            ]}
          >
            {/* Pickup Section */}
            <ShipmentDetails
              title="shipmentDetail.pickupLocation"
              sectionTitle="book.newQuote.fromPickUp"
              company={data.customerQuotes.data[0].origin.companyName}
              address={data.customerQuotes.data[0].origin.address}
              dateTime={data.customerQuotes.data[0].pickupDateTime}
              flight={quoteData.customerShipment.flights[0]}
              contact={quoteData.customerShipment.origin.contact}
            />
            <Col span={12} className="flight-itinerary">
              <div style={{ marginBottom: '16px' }}>
                <QuoteFlightItinerary />
              </div>
              <ShipmentDetailMap shipment={shipment} />
              <NoticeSection />
            </Col>
            <ShipmentDetails
              title="shipmentDetail.deliveryLocation"
              sectionTitle="book.newQuote.toDeliver"
              company={data.customerQuotes.data[0].destination.companyName}
              address={data.customerQuotes.data[0].destination.address}
              dateTime={data.customerQuotes.data[0].deliveryDateTime.scheduled}
              flight={quoteData.customerShipment.flights[0]}
              contact={quoteData.customerShipment.destination.contact}
            />
          </Row>
        )}
      </ModalPage>
    </ModalOverlay>
  );
};

export default QuoteResultPage;
