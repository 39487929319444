import { Form, Row } from 'antd';
import classNames from 'classnames';
import { get, isEmpty, noop } from 'lodash-es';
import { useCallback, useContext, useEffect, useImperativeHandle } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormItemOverrideContext } from '../../../components/forms/FormItem';
import FormList from '../../../components/forms/FormList';
import SilentFormValidation from '../../../components/forms/SilentFormValidation';
import { FormItemFieldRegistration } from '../../../components/forms/basicFormElements';
import { DynamicFormDependenciesProvider } from '../../../components/forms/dynamic/dynamicFormDependencies';
import { isFormSectionNonEmpty } from '../../../components/forms/formHelpers';
import {
  FormInterceptorContext,
  FormMode,
  useFormContext,
} from '../../../components/forms/forms';
import { withIntermediateSubformState } from '../../../components/forms/subformDecorators';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { useScrollToThis } from '../../../utils/scrollUtils';
import { PackageConsistentWeightModeDialog } from './packageListDialogs';
import {
  usePackageList,
  usePackagesIntermediateSubformStates,
} from './usePackageList';
import {
  AddOrCopyPackageButtons,
  PackageFormsBigScreen,
  PackageFormsSmallScreen,
} from './PackageListPackages';
import { PackageListSummary } from './PackageListSummary';
import { QuotePackagesPreviewMode } from './QuotePackagesPreviewMode';
import { QuotePackagesEditingMode } from './QuotePackagesEditingMode';

const PackageFormInner = withIntermediateSubformState({
  renderEditing: props => <QuotePackagesEditingMode {...props} />,
  renderPreview: props => <QuotePackagesPreviewMode {...props} />,
});

function QuotePackageForm({
  index,
  remove: removeIndex,
  name,
  fieldKey,
  hasError,
  ...rest
}) {
  const remove = () => removeIndex(index);
  const ref = useScrollToThis({ active: hasError });

  const { values } = useFormContext();
  const isFormLoaded = !!values.packages;
  const outerValue = get(values, ['packages', index]);
  const formMode =
    isFormSectionNonEmpty(outerValue) || !isFormLoaded
      ? FormMode.EDIT
      : FormMode.ADD;

  return (
    <>
      <Form.Item noStyle name={name} fieldKey={fieldKey}>
        <PackageFormInner
          index={index}
          remove={remove}
          formMode={formMode}
          {...rest}
        />
      </Form.Item>
      <div
        ref={ref}
        className={classNames('NewShipmentPackage-ErrorMessage', {
          'has-content': hasError,
        })}
      >
        {hasError && <FormattedMessage id="error.package" />}
      </div>
    </>
  );
}

function QuotePackageListPackagesInner({
  onEditedPackageChange,
  fields,
  add,
  onAddPackage,
  remove,
  packageErrors,
  pkgControlsRef,
}) {
  const { disabled, viewMode } = useContext(FormItemOverrideContext);
  const { onBlur: onBlurGlobal } = useContext(FormInterceptorContext);

  const { values, parentContext } = useFormContext();

  const {
    getIntermediateSubformState,
    removeFromState,
    startEditing,
    isAnyEdited,
  } = usePackagesIntermediateSubformStates({ onEditedPackageChange });

  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm || media.md;

  const noPackageExists = isEmpty(fields);

  useEffect(() => {
    if (noPackageExists) {
      add({});
      startEditing(0);
    }
  }, [
    add,
    noPackageExists,
    isAnyEdited,
    parentContext?.values,
    startEditing,
    values,
    getIntermediateSubformState,
  ]);

  const addAndStartEdit = useCallback(
    val => {
      add(val);
      const index = fields.length;
      startEditing(index);
      onAddPackage({ index, value: val });
    },
    [add, fields.length, onAddPackage, startEditing]
  );

  const removeIncludingState = index => {
    removeFromState(index);
    remove(index);
    if (getIntermediateSubformState(index).editing) {
      onEditedPackageChange(null, null);
    }
    // Removal should trigger the same response as blur
    onBlurGlobal();
  };

  useImperativeHandle(pkgControlsRef, () => ({ addAndStartEdit }));

  if (noPackageExists) {
    const showInitialAddButton = !disabled && !viewMode;
    if (!showInitialAddButton) {
      return null;
    }
    return (
      <div>
        <AddOrCopyPackageButtons add={addAndStartEdit} />
      </div>
    );
  }

  const showAddButton = !disabled && !viewMode && !isAnyEdited;
  const packageForms = (fields || []).map((field, index) => (
    <QuotePackageForm
      {...field}
      index={index}
      remove={removeIncludingState}
      {...getIntermediateSubformState(index)}
      hasError={packageErrors[index]}
    />
  ));

  return (
    <>
      {isSm ? (
        <PackageFormsSmallScreen
          packageForms={packageForms}
          packageErrors={packageErrors}
        />
      ) : (
        <PackageFormsBigScreen
          packageForms={packageForms}
          packageErrors={packageErrors}
        />
      )}
      {showAddButton && <AddOrCopyPackageButtons add={addAndStartEdit} />}
    </>
  );
}

export default function QuotePackageListPackages({
  onEditedPackageChange: onEditedPackageChangeOuter,
  activeTemplateCounter,
  pkgControlsRef,
}) {
  const {
    onAddPackage,
    isOpen,
    close,
    onEditedPackageChange,
    validateAllPackages,
    parentDependencies,
    validationRef,
    packageErrors,
  } = usePackageList(onEditedPackageChangeOuter);

  return (
    <div className="NewShipmentPackage">
      <Row className="quotes-form-package-summary">
        <PackageListSummary />
      </Row>
      <FormItemFieldRegistration
        name="allPackagesValidator"
        rules={[{ validator: validateAllPackages }]}
      />
      <SilentFormValidation ref={validationRef}>
        {({ values, index }) => (
          <DynamicFormDependenciesProvider
            values={{ ...parentDependencies, packages: values }}
          >
            <QuotePackagesEditingMode
              index={index}
              pieceListProps={{
                editing: true,
                setEditing: noop,
                tmpValues: values,
              }}
            />
          </DynamicFormDependenciesProvider>
        )}
      </SilentFormValidation>
      <FormList name="packages">
        {(fields, { add, remove }) => (
          <QuotePackageListPackagesInner
            onEditedPackageChange={onEditedPackageChange}
            activeTemplateCounter={activeTemplateCounter}
            fields={fields}
            add={add}
            onAddPackage={onAddPackage}
            remove={remove}
            packageErrors={packageErrors}
            pkgControlsRef={pkgControlsRef}
          />
        )}
      </FormList>
      <PackageConsistentWeightModeDialog isOpen={isOpen} close={close} />
    </div>
  );
}
