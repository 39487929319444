import { gql } from '@apollo/client';

import { listLocationFragment } from './commonFragments';

export const CREATE_QUOTE_MUTATION = gql`
  mutation CreateQuoteMutation($input: QuoteInput!) {
    createQuote(input: $input) {
      expiryDate
      amounts {
        type
        value
        desc
      }
      quotedBy
      totalCharges
      currency
      readyDate
      quoteNumber
      account
    }
  }
`;

export const DELETE_QUOTE_MUTATION = gql`
  mutation DeleteQuoteMutation($referenceNo: String!) {
    deleteQuote(referenceNo: $referenceNo)
  }
`;

export const QUOTES_QUERY = gql`
  query CustomerQuotesQuery($pageIndex: Int, $pageSize: Int, $filter: QuoteFilter, $sort: Sort) {
    customerQuotes(pageIndex: $pageIndex, pageSize: $pageSize, filter: $filter, sort: $sort) {
      pageIndex
      pageSize
      total
      data {
        metadata {
          qolAccount {
            number
            name
          }
        }
        quoteNumber
        referenceNo
        origin ${listLocationFragment}
        destination ${listLocationFragment}
        quoteStatus
        pickupDateTime {
          scheduled
        }
        deliveryDateTime {
          scheduled
        }
        expiryDateTime
        shippersReferences
      }
    }
  }
`;
