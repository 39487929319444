import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import {
  DateFormatMedium,
  TimeFormatShort,
} from '../../../utils/dateFormatting';

const getUserLocale = () => navigator.language || 'en-US';
const getTimeZoneAbbreviation = dateString => {
  const date = new Date(dateString);
  const userLocale = getUserLocale();
  return new Intl.DateTimeFormat(userLocale, { timeZoneName: 'short' })
    .formatToParts(date)
    .find(part => part.type === 'timeZoneName')?.value;
};

export default function DateTimeDisplay({
  label,
  dateTime,
  formatComponent: FormatComponent = DateFormatMedium,
  dayStyle,
  timeStyle,
}) {
  const formattedDate = moment(dateTime);
  const timeZoneAbbr = getTimeZoneAbbreviation(formattedDate);

  return (
    <div>
      {label && <FormattedMessage id={label} />}
      <span style={dayStyle}>
        <FormatComponent value={formattedDate} />
        {' | '}
      </span>
      <span style={timeStyle}>
        <TimeFormatShort value={formattedDate} /> {timeZoneAbbr}
      </span>
    </div>
  );
}
