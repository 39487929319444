import { isEqual, some, stubFalse } from 'lodash-es';
import { useContext, useRef, useState } from 'react';

import { DynamicFormDependenciesContext } from '../../../components/forms/dynamic/dynamicFormDependencies';
import { useFormContext } from '../../../components/forms/forms';
import { getValidPackages } from '../shipmentCommon';
import { useConsistentWeightModeDialog } from './PackageListDimensionsInputs';
import { useArrayState } from '../../../common/utils/hookUtils';
import { useArrayDialogControls } from '../../../common/utils/dialogUtils';

export function usePackageList(onEditedPackageChangeOuter) {
  const { formInstance } = useFormContext();
  const { values: parentDependencies } = useContext(
    DynamicFormDependenciesContext
  );
  const validationRef = useRef();
  const [packageErrors, setPackageErrors] = useState([]);

  const { onAddPackage, isOpen, close } = useConsistentWeightModeDialog();

  const onEditedPackageChange = (changed, all, { index } = {}) => {
    onEditedPackageChangeOuter(changed, all);
    const newErrors = [...packageErrors];
    // It's not possible to save invalid package, so we just assume it's valid and set it that way
    newErrors[index] = false;
    if (!isEqual(packageErrors, newErrors)) {
      setPackageErrors(newErrors);
    }
  };

  const validateAllPackages = async () => {
    const { packages } = formInstance.getFieldsValue();
    const validPackages = getValidPackages(packages);
    const errors = [];

    let i = 0;
    for (const pkg of validPackages) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await validationRef.current.validate(pkg, { index: i });
        errors.push(false);
      } catch (e) {
        errors.push(true);
      }
      i += 1;
    }
    setPackageErrors(errors);

    if (some(errors)) {
      throw new Error();
    }
  };

  return {
    onAddPackage,
    isOpen,
    close,
    onEditedPackageChange,
    validateAllPackages,
    parentDependencies,
    validationRef,
    packageErrors,
  };
}

export function usePackagesIntermediateSubformStates({
  onEditedPackageChange,
}) {
  const {
    values: allTmpValues,
    setValue: setTmpValuesAtIndex,
    removeValue: removeTmpValuesAtIndex,
    setAllValues: setTmpValues,
  } = useArrayState();
  const {
    values: editStates,
    setValue: setEditState,
    removeValue: removeEditState,
    setAllValues: setEditStates,
  } = useArrayState();
  const {
    values: allPieceTmpValues,
    setValue: setPieceTmpValuesAtIndex,
    removeValue: removePieceTmpValuesAtIndex,
    setAllValues: setPieceTmpValues,
  } = useArrayState();
  const {
    values: pieceEditStates,
    setValue: setPieceEditState,
    removeValue: removePieceEditState,
    setAllValues: setPieceEditStates,
  } = useArrayState();
  const { getDialogControls, removeDialogControls, setOpenStates } =
    useArrayDialogControls();

  const onTmpValuesChange = (index, vals) => {
    setTmpValuesAtIndex(index, vals);
    onEditedPackageChange && onEditedPackageChange(vals, vals, { index });
  };

  return {
    getIntermediateSubformState: index => ({
      editing: !!editStates[index],
      setEditing: value => setEditState(index, value),
      tmpValues: allTmpValues[index],
      onTmpValuesChange: vals => onTmpValuesChange(index, vals),
      pieceListProps: {
        editing: !!pieceEditStates[index],
        setEditing: value => setPieceEditState(index, value),
        tmpValues: allPieceTmpValues[index],
        onTmpValuesChange: vals => setPieceTmpValuesAtIndex(index, vals),
        ...getDialogControls(index),
      },
    }),
    startEditing: index => setEditState(index, true),
    isAnyEdited: some(editStates),
    removeFromState: index => {
      removeTmpValuesAtIndex(index);
      removeEditState(index);
      removePieceTmpValuesAtIndex(index);
      removePieceEditState(index);
      removeDialogControls(index);
    },
    removeAllFromState: () => {
      setTmpValues([]);
      setEditStates([]);
      setPieceTmpValues([]);
      setPieceEditStates([]);
      setOpenStates([]);
    },
    stopEditingAll: () => setEditStates(editStates.map(stubFalse)),
  };
}
